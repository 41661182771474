let ossConfigOption = {
    'guoranwisdom': {
        region: "oss-cn-zhangjiakou",
        //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
        accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
        accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
        // stsToken: '<Your securityToken(STS)>',
        bucket: "guoranwisdom",
    },
    'guoranopen-zjk': {
        region: "oss-cn-zhangjiakou",
        accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
        accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
        bucket: "guoranopen-zjk",
    },
    "askbotopen-ls": {
        region: "oss-ap-southeast-1",
        accessKeyId: "LTAI5tAusPLDNJJwkvUbqi2T",
        accessKeySecret: "xqPVaunOIbvTe3g9qsXal2IZO6RftK",
        bucket: "askbotopen-ls",
    },
    "askbotwisdom-ls": {
        region: "oss-ap-southeast-1",
        accessKeyId: "LTAI5tAusPLDNJJwkvUbqi2T",
        accessKeySecret: "xqPVaunOIbvTe3g9qsXal2IZO6RftK",
        bucket: "askbotwisdom-ls",
    }
};

function generateOssConfig(bucketType) {
    let config;
    if (process.env.VUE_APP_SOURCE === 'lishi') {
        if (bucketType === 'open') {
            config = ossConfigOption['askbotopen-ls']
        } else {
            config = ossConfigOption['askbotwisdom-ls']
        }
    } else if (process.env.VUE_APP_SOURCE === 'askbot' || process.env.VUE_APP_CONFIG_ENV !== 'production') {
        if (bucketType === 'open') {
            config = ossConfigOption['guoranopen-zjk']
        } else {
            config = ossConfigOption['guoranwisdom']
        }
    }
    return config;
}

export { generateOssConfig }