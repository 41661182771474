
const state = () => ({
    knowledgeDetail:{},
    textWatermarkStr:""
})

const getters = {
    getTextWatermarkStr:(state) => {
        return state.textWatermarkStr;
    }
}

const actions = {
    updateKnowledgeDetail({ commit }, knowledge) {
        commit("setKnowledgeDetail", knowledge);
    },
    updateTextWatermarkStr({ commit }, textValue) {
        commit("setTextWatermarkStr", textValue);
    },
}

const mutations = {
    setKnowledgeDetail(state, combinationInfo){
        console.log('state',state,combinationInfo)
        state.knowledgeDetail = combinationInfo
    },
    setTextWatermarkStr(state,textValue){
        state.textWatermarkStr = textValue;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}