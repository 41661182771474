<template>
    <div class="pay-popup">
        <ask-dialog @closeEvent="closeEvent" :popZindex="3333" v-show="promoteVisable" class="pay-popup-content" :haveTips="true" :isNeedScroll="false">
            <div slot="popup-name">{{popupTitle}}</div>
			<div slot="popup-tip">{{popupTips}}</div>
            <div slot="popup-con">
                <template v-if="!isPaySuccess">
                    <div :class="['choose-content',popupType !== 'version' ? 'flow' : '']">
                    <!-- 购买次数包 -->
                        <template v-if="popupType === 'flow' || popupType === 'capacity'">
                            <div 
                                :class="['flow-times-item',activeIndex === index ? 'active' : '', versionLoading?'forbid-click':'']" 
                                v-for="(item,index) in priceList" 
                                :key="index"
                                @click="chooseflowTimes(item,index,'flow')">
                                <div :class="['times',currentVersion.vipType != 3 ? 'min' : 'max']">{{item.name}}</div>
                                <div class="price">{{item.price}} <span>元</span> </div>
                            </div>
                        </template>
                        <!-- 升级版本 -->
                        <template v-if="popupType === 'version'">
                            <div 
                                :class="['version-item',activeIndex === index ? 'active' : '', versionLoading?'forbid-click':'']" 
                                v-for="(item,index) in popupVersionList" 
                                :key="index"
                                @click="chooseflowTimes(item,index,'version')">
                                {{item.text}} ¥{{item.price}}
                            </div>
                        </template>
                    </div>
                    <div class="choose-content-tips" v-if="popupType === 'version'">
                        <span v-if="activeItem.time === 0 || activeItem.time === 2">{{activeItem.text}}可随时取消  <i class="iconfont guoran-tishi"></i></span>
                        <span v-else>低至 ¥ {{activeItem.averageDailyPrice}}/天</span>
                    </div>
                    <el-select v-model="paymentMethod" placeholder="请选择" popper-class="choose-pay-method">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                                <i :class="item.icon"></i>
                                <span>{{ item.label }}</span>
                        </el-option>
                    </el-select>
                    <div 
                        v-if="loading"
                        class="loading-content"
                        v-loading="loading"
                        element-loading-text="拼命加载中..."
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="#fff"></div>
                    <template v-else>
                        <div 
                            v-if="flag"
                            class="wechatcode" >
                            <div id="wechatcode-content"  v-if="flag"></div>
                            <div class="pay-method-tips"  v-if="flag">请使用{{paymentMethod === 'wx' ? '微信' : '支付宝'}}扫一扫付款</div>
                        </div>
                        <div class="code-fail"  v-else>
                            <template v-if="(activeItem.time === 0 || activeItem.time === 2) || this.currentVersion.continuousStatus">
                                <div>二维码生成失败</div>
                                <div>您已开通自动扣费服务，如需变更版本请先在微信关闭自动扣费</div>
                            </template>
                            <template v-else>
                                <div>二维码生成失败</div>
                                <div>请刷新重试</div>
                                <div class="refresh-code" @click="getQrCode">刷新二维码</div>
                            </template>
                        </div>
                    </template>
                </template>
                <!-- 支付成功 -->
                <div v-else class="pay-success">
                    <img src="../images/pay-success.png" alt="" class="">
                    <div class="pay-success-tips">{{closeTime}}s后将自动关闭弹窗</div>
                </div>
               
            </div>
        </ask-dialog>
    </div>
</template>

<script>
import AskDialog from "../../testComponents/AskDialog.vue";
import { requestUrl } from "@/http/requestUrl";
import QRCode from 'qrcodejs2' // 引入qrcode
export default {
    data (){
        return {
            popupTitle:"",
            popupTips:"",
            popupType:"version", // version 升级版本   flow 流量包  capacity 容量包
            activeItem:{},
            activeIndex:0,
            paymentMethod:"wx",
            options:[{
                label:"微信支付",
                value:"wx",
                icon:"iconfont guoran-tongyichicun-weixin",
            }],
            // ,{
            //     label:"支付宝支付",
            //     value:"zfb",
            //     icon:"iconfont guoran-zhifubaozhifu",
            // }
            popupVersionList:[],
            loading:true,
            wechatPayUrl:"",
            flag:false,
            priceList:[], // 价格列表
            isPaySuccess:false, // 是否支付成功
            closeTimer:null,
            closeTime:3,
            versionLoading: false,
        }
    },
    components:{AskDialog},
    props:{
        promoteVisable:{
            type:Boolean,
            default:false
        },
        activeVersion:{
            type:Object,
            default(){
                return {}
            }
        },
        currentVersion:{
           type:Object,
            default(){
                return {}
            } 
        },
        // priceList:{
        //     type:Array,
        //     default(){
        //         return[]
        //     }
        // }
    },
    watch:{
        promoteVisable(n){
            if(n){
                this.resetData();
                this.isPaySuccess = false;
                if(this.popupType === 'version'){
                    this.selectVipPrice();
                } else {
                    if(this.popupType === 'flow'){
                        this.getSelectFlowPrice();
                    } else if (this.popupType === 'capacity'){
                        this.getSelectCapacityPrice()
                    }
                }
            }
        },
    },
    methods:{
        resetData(){
            this.activeItem = {};
            this.activeIndex = 0;
        },
        // 查询容量包价格
        getSelectCapacityPrice(){
            let url = '';
            if(this.currentVersion.vipType === 3){
                url = requestUrl.pay.selectCapacityPrice + '?personage=true&company=true'
            } else {
                url = requestUrl.pay.selectCapacityPrice + '?personage=true&company=false'
            }
            this.$http.get(url).then((res) => {
                if (res.data.code == 0) {
                    this.priceList = res.data.data;
                    this.activeItem = this.priceList[0];
                    this.activeIndex = 0;
                    this.loading = false;
                    this.getQrCode();
                }
            })
        },
        // 流量包/版本选择
        chooseflowTimes(item,index){
            if(this.activeIndex === index) return;
            this.activeItem = item;
            this.activeIndex = index;
            this.wechatPayUrl = '';
            console.log(this.flag,'this.flag');
            if(item.time === 0 || item.time === 2){
                this.loading = false;
                this.flag = false;
            } else {
                this.flag = false;
                console.log('获取支付二维码');
                this.versionLoading = true; // 防止多次点击
                this.getQrCode();
            }
        },
        closeEvent(){
            this.$emit('update:promoteVisable',false);
            clearInterval(this.closeTimer);
            this.closeTimer = null;
            this.$emit('paySuccess');
        },
        // 查询各版本会员价格
        selectVipPrice(){
            console.log(this.activeVersion,'activeVersion');
            // 1：超级会员，2：高级会员
            this.$http.get(requestUrl.pay.selectVipPrice+"?type=" + this.activeVersion.type).then((res) => {
                if (res.data.code == 0) {
                    let list = [];
                    res.data.data.forEach(item => {
                        switch (item.time) {
                            case 0:
                                item.text = '连续包月';
                                item.averageDailyPrice = (item.price / 30).toFixed(2)
                                break;
                            case 1:
                                item.text = '包月';
                                item.averageDailyPrice = (item.price / 30).toFixed(2)
                                list.push(item)
                                break;
                            case 2:
                                item.text = '连续包年';
                                item.averageDailyPrice = (item.price / 365).toFixed(2)
                                break;
                            case 3:
                                item.text = '包年';
                                item.averageDailyPrice = (item.price / 365).toFixed(2)
                                list.push(item)
                                break;
                        }
                    })
                    this.popupVersionList = list;
                    this.activeItem = this.popupVersionList[0];
                    this.activeIndex = 0;
                    this.loading = false;
                    this.getQrCode();
                }
            })
        },
        // 查询流量包价格
        getSelectFlowPrice(){
            let url = '';
            if(this.currentVersion.vipType === 3){
                url = requestUrl.pay.selectFlowPrice + '?personage=true&company=true'
            } else {
                url = requestUrl.pay.selectFlowPrice + '?personage=true&company=false'
            }
            this.$http.get(url).then((res) => {
                if (res.data.code == 0) {
                    this.priceList = [];
                    res.data.data.forEach(item => {
                        if(item.flowType != 9){
                            this.priceList.push(item)
                        }
                    })
                    this.activeItem = this.priceList[0];
                    this.activeIndex = 0;
                    this.loading = false;
                    this.getQrCode();
                }
            })
        },
        // 获取支付二维码
        getQrCode(){
            this.flag = false;
            this.loading = true;
            let params = {
                "type":null, // 购买会员类型 1：超级会员，2：高级会员
                "time":null,  // 开通时长 0：包月，1：包年，2：连续包月，3：连续包年
                "flowType":null,// 购买流量包类型 0：100次流量包，1：500次流量包，2：1000次流量包
                "capacityType":null,
                // type、time为购买会员必须参数，flowType为购买流量包必须参数，capacityType为购买容量包必须参数
            }
            if(this.popupType === 'version'){
                params.type = this.activeItem.type
                params.time = this.activeItem.time
            } else if (this.popupType === 'capacity'){
                params.capacityType = this.activeItem.capacityType
            } {
                params.flowType = this.activeItem.flowType
            }
            this.$http.post(requestUrl.pay.getWxPayCodeUrl,params).then((res) => {
                console.log(res,'获取支付二维码');
                if (res.data.code == 0) {
                    this.loading = false;
                    this.versionLoading = false;
                    this.flag = true;
                    console.log(this.loading,this.flag);
                    this.wechatPayUrl = res.data.data
                    // 生成二维码
                    this.$nextTick(() => {
                        new QRCode('wechatcode-content', {
                            width: 200,
                            height: 200,
                            text: this.wechatPayUrl, // 二维码地址
                            colorDark: "#000",
                            colorLight: "#fff",
                        })
                    })
                } else {
                    this.flag = false;
                    this.loading = false;
                    this.versionLoading = false;
                }
            }).catch((err) => {
                this.flag = false;
                this.loading = false;
                console.log(err,'err');
            })
        },
        
         // 支付成功后的处理
        paySuccessHandler(){
            clearInterval(this.closeTimer);
            this.closeTimer = null;
            this.closeTime = 3;
            this.isPaySuccess = true;
            this.popupTitle = '付款成功';
            this.popupTips = '服务将在5分钟内生效';
            this.closeTimer = setInterval(() => {
                this.closeTime--
                if(this.closeTime <= 0){
                    clearInterval(this.closeTimer);
                    this.closeTimer = null;
                    this.$emit('paySuccess');
                }
            }, 1000);
        }

    }
}
</script>
<style lang="less">
.choose-pay-method{
    i{
        margin-right: 6px;
    }
    .guoran-zhifubaozhifu{
        color: #2580fc;
    }
    .guoran-tongyichicun-weixin{
        color: #22bc3b
    }
}
</style>
<style lang="less" scoped>
.pay-popup{
    .forbid-click {
        // opacity: 0.7;
        pointer-events: none;
        cursor: not-allowed;
    }
    .pay-popup-content{
        .choose-content{
            display: flex;
            margin-bottom: 20px;
            &.flow{
                justify-content: space-between;
            }
            .flow-times-item{
                width: 156px;
                height: 107px;
                background: #F1F5FF;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 2px solid #fff;
                &.active{
                    border-color:#A1B9FF;
                }

                .times{
                    // width: 94px;
                    min-width: 94px;
                    // padding: 0 10px;
                    height: 31px;
                    background: #366AFF;
                    border-radius: 16px;
                    color: #fff;
                    text-align: center;
                    line-height: 31px;
                    margin-bottom: 10px;
                    &.min{
                        padding: 0;
                    }
                }
                .price{
                    font-size: 18px;
                    color: #FE5965;
                    height: 15px;
                    span{
                        color: #000000;
                        font-size: 14px;
                    }
                }
            }
            .version-item{
                height: 30px;
                border-radius: 15px;
                padding: 0 16px;
                line-height: 30px;
                color: #000;
                border: 1px solid #E2E6EE;
                cursor: pointer;
                margin-right: 20px;
                &.active{
                    color: #fff;
                    background: linear-gradient(249deg, #5A7BE7, #8DABFF);
                }
            }
        }
        .choose-content-tips{
            margin-bottom: 26px;
            color: #A0A0A0;
            .guoran-tishi{
                color: #CAD3F0;
            }
        }
        .el-select{
            width: 100%;
            margin-bottom: 40px;
        }
        .loading-content{
            height: 250px;
        }
        .wechatcode{
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 250px;
            #wechatcode-content{
                width: 200px;
                height: 200px;
            }
             .pay-method-tips{
                text-align:center;
                margin-top: 14px;
            }
        }
        .code-fail{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 250px;
            >div{
                font-weight: bold;
                margin-bottom: 4px;
                font-size: 15px;
            }
            .refresh-code{
                background: linear-gradient(249deg, #5A7BE7, #8DABFF);
                color: #fff;
                height: 30px;
                width: 100px;
                border-radius: 15px;
                line-height: 30px;
                border: 1px solid #E2E6EE;
                text-align: center;
                cursor: pointer;
                margin-top: 10px;
            }
        }
        .pay-success{
            display: flex;
            align-items: center;
            flex-direction: column;
            img{
                width: 302px;
                height: 270px;
                margin: 60px 0 80px 0;
            }
            .pay-success-tips{
                font-size: 13px;
                color: #616161;
            }
        }
    }
}
</style>