import Vue from 'vue';
import Vuex from 'vuex';
import user from './moudles/user.js';
import apphandle from "./moudles/apphandle.js";
import notice from "./moudles/notice.js";
import knowledge from "@/store/moudles/knowledge";
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        user,
        apphandle,
        notice,
        knowledge
    },
});
export default store;