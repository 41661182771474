<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    localStorage.removeItem('_token')
  },
  mounted() {
    this.$nextTick(() => {
      console.log('systemLanguage',sessionStorage.getItem('systemLanguage'))
      let systemLanguage = sessionStorage.getItem('systemLanguage');
      if (systemLanguage){
        this.$i18n.locale = systemLanguage;
      }
    })
  }
}
</script>
<style lang="less">
.alct-in-reg-ask-content-popover {
    background-color: rgba(0, 0, 0, 0.6) !important;
}
.dm-plugin-select-box-popover {
    padding: 20px 0 !important;
}
.modelDrawerContainerPopover {
    margin-left: 60px !important;
    padding: 0 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f7fb;
}
.ask-login-realusername {
    .el-input__inner {
        border-radius: 20px;
    }
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.el-message{
  z-index: 9999 !important;
}
.commitLoadMore{
  min-width: 110px!important;
  padding: 8px 0!important;
  .popoverItem{
    display: flex;
    align-items: center;
    height: 36px;
    cursor:pointer;
    padding: 0 12px;
    i{
      margin-right: 10px;
      font-size: 14px;
    }
  }
  .popoverItem:hover{
    background: #F5F8FF;
  }
}
.previewPageMore{
  min-width: 110px!important;
  padding: 10px 0!important;
  .tooltip{
    display: flex;
    align-items: center;
    height: 36px;
    cursor:pointer;
    padding: 0 12px;
    .iconfont{
      margin-right: 6px;
    }
  }
  .tooltip:hover{
    background: #F5F8FF;
  }
}
</style>
