<!--AI写作/协作次数超出或容量超出 
    vipType 0：免费版，1：超级会员，2：高级会员   3 企业版
--->
<template>
    <div class="beyond-limit">
        <ask-dialog @closeEvent="closeEvent" :popZindex="3333" v-if="canUploadDiaVisible" :haveTips="true" :isNeedScroll="false" class="dialog-footer-can-use-popup">
            <div slot="popup-name">{{canUploadDialogTitle}}</div>
            <div slot="popup-tip">{{canUploadDialogTips}}</div>
            <div slot="popup-con" class="popup-con-box">
				<img v-if="exceedObj.type === 'total' && currentVersion.vipType === 1" height="300px" src="./ratelckeditor/images/chat.png" alt="" srcset="">
                <img height="300px" v-else-if="exceedObj.beyondType === 'capacity' && currentVersion.vipType === 1 " src="../testComponents/ratelckeditor/images/chat.png" alt="" srcset="">
				<img v-else height="300px" src="../../assets/images/uploadcanuse.png" alt="" srcset="">
            </div>
			<div slot="dialog-footer" class="dialog-footer">
                <template v-if="currentVersion.vipType < 3">
                    <div class="promote-version" v-if="currentVersion.vipType !== 1" @click="openPayVersion">升级版本</div>
                    <div class="buy-times" v-if="exceedObj.beyondType === 'flow' && currentVersion.vipType !== 0 && !currentVersion.expire" @click="openPayVersion">购买AI次数</div>
                </template>
                <template v-else>
                    <div class="promote-version" @click="openBuy(exceedObj.beyondType === 'capacity' ? 'capacity' : 'flow')">{{exceedObj.beyondType === 'capacity' ? '购买容量' : '购买次数'}}</div>
                    <div class="buy-times" @click="applyForCompany">向企业申请</div>
                </template>
			</div>
        </ask-dialog>
        <ask-dialog @closeEvent="showMorePopup = false" :popZindex="3333" v-if="showMorePopup" :haveTips="false" :isNeedScroll="false" class="buy-style-popup" :popWidth="500">
            <div slot="popup-name">获得更多流量或容量</div>
            <div slot="popup-con" class="popup-con-box">
                <div class="buy-style" @click="openBuy('flow')">购买次数</div>
               <!-- <div class="buy-style" @click="openBuy('capacity')">购买容量</div> -->
               <div class="buy-style" @click="openApplyInfo">向企业申请</div>
            </div>
        </ask-dialog>
        <ask-dialog @closeEvent="showApplyInfoPopup=false" :popZindex="3333" v-if="showApplyInfoPopup" :haveTips="true" :isNeedScroll="false" class="apply-info-popup" :popWidth="500">
            <div slot="popup-name">提交申请</div>
            <div slot="popup-tip">申请次数或容量</div>
            <div slot="popup-con" class="popup-con-box">
               <el-form :label-position="'top'" label-width="80px" :mode="form">
                    <el-form-item label="申请内容">
                        <el-checkbox-group v-model="form.type">
                            <el-checkbox @click.native="clickFlow" label="flow" name="flow" @change="flowChange" :disabled="!combinationInfo.bigModel || (combinationInfo.bigModel && !combinationInfo.canActivate)">次数</el-checkbox>
                            <el-checkbox label="capacity" name="capacity">容量</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="申请说明">
                        <span slot="label">
                            <span>申请说明</span>
                            <span class="tips">(选填)</span>
                        </span>
                        <el-input type="textarea" v-model="form.desc" resize="none" placeholder="请输入"></el-input>
                    </el-form-item>
               </el-form>
            </div>
            <div slot="dialog-footer" class="dialog-footer">
                <el-button class="cancel-btn"  @click="showApplyInfoPopup = false">取 消</el-button>
                <el-button type="primary" class="confirm-btn" @click="submitFlowApply">提 交</el-button>
            </div>
        </ask-dialog>
        <payPopup 
            ref="payPopup" 
            :currentVersion="currentVersion"
            :promoteVisable.sync="promoteVisable" 
            :activeVersion="activeVersion"
            @paySuccess="paySuccess">
        </payPopup>
    </div>
</template>

<script>
import AskDialog from "./AskDialog.vue";
import payPopup from '../payVersion/components/payPopup.vue';
import { requestUrl } from "../../http/requestUrl";
import { mapGetters,mapActions, mapState} from 'vuex';
export default {
    data(){
        return {
            // showMorePopup:false,
            // showApplyInfoPopup:false,
            form:{
                type:[],
                desc:''
            },
            promoteVisable:false
        }
    },
    components:{AskDialog,payPopup},
    props:{
        canUploadDiaVisible:{
            type:Boolean,
            default:false,
        },
        canUploadDialogTitle:{
            type:String,
            default:'',
        },
        canUploadDialogTips:{
            type:String,
            default:'',
        },
        exceedObj:{
            type:Object,
            default(){
                return {
                    type:"",
                    beyondType:""
                }
            },
        },
        currentVersion:{
            type:Object,
            default(){
                return {}
            },
        },
        showMorePopups:{
            type:Boolean,
            default:false,
        },
        showApplyInfoPopups:{
            type:Boolean,
            default:false,
        }
    },
    computed:{
        ...mapGetters('user', {
            currentVersion: 'getCurrentVersion'
        }),
        ...mapGetters('user', {
            payStatus: 'getPayStatus'
        }),
        ...mapState({
            // 智能写作次数/容量 套餐使用信息
            combinationInfo: state => state.user.combinationInfo,
        }),
        showMorePopup: {
			get() {
				return this.showMorePopups
			},
			set(value) {
				this.$emit('update:showMorePopups', value)
			}
		},
        showApplyInfoPopup: {
			get() {
				return this.showApplyInfoPopups
			},
			set(value) {
				this.$emit('update:showApplyInfoPopups', value)
			}
		},
    },
    watch:{
        payStatus(n){
            console.log(n,'nnnnn');
            if(n === 'success'){
                this.$refs.payPopup.paySuccessHandler();
            }
        },
        showApplyInfoPopup(n){
            if(n){
                this.form = {
                    type:[],
                    desc:''
                }
            }
        }
    },
    mounted(){
        // console.log(this.exceedObj,'exceedObj');
    },
    // props:['canUploadDiaVisible','canUploadDialogTitle','canUploadDialogTips','exceedObj','currentVersion'],
    methods:{
        ...mapActions('user', [
            'updateCombinationInfo'
        ]),
        ...mapActions('user', [
            'updatePayStatus'
        ]),
        closeEvent(){
            this.$emit('update:canUploadDiaVisible',false)
        },
        openPayVersion(){
            this.$emit('update:canUploadDiaVisible',false)
            this.$router.push({name:"payVersionView"})
        },
        applyForCompany(){
            this.$emit('update:canUploadDiaVisible',false)
            this.showApplyInfoPopup = true;
        },
        openApplyInfo(){
            this.showMorePopup = false;
            this.showApplyInfoPopup = true;
        },
        submitFlowApply(){
            // let params = {
            //     // "mainId":localStorage.getItem('_mainId'),
            //     // "corpId":localStorage.getItem('_corpId'),
            //     // "userId":localStorage.getItem('_uid'),
            //     // "description": this.form.desc// 申请理由
            //     "mainId":'e82262a903b1409b9380270563dbd21a',
            //     "corpId":null,
            //     "userId":'2144',
            //     "description": this.form.desc// 申请理由
            // }
            let params = {
                flowApplyList:null,
                capacityApplyList:null
            }
            if(this.form.type.length === 0){
                this.messageFn('warning', '请选择要申请的内容');
                return false
            }
            if(this.form.type.indexOf('flow') !== -1){
                params.flowApplyList = {
                    "mainId":localStorage.getItem('_mainId'),
                    // "corpId":localStorage.getItem('_corpId'),
                    "userId":localStorage.getItem('_uid'),
                    "description": this.form.desc// 申请理由
                }
            } 
            if(this.form.type.indexOf('capacity') !== -1) {
                params.capacityApplyList = {
                    "mainId":localStorage.getItem('_mainId'),
                    // "corpId":localStorage.getItem('_corpId'),
                    "userId":localStorage.getItem('_uid'),
                    "description": this.form.desc// 申请理由
                }
            }
            this.$http.post(requestUrl.company.submitFlowApply,params).then((res) => {
                console.log(res,'res');
                if (res.data.code == 0) {
                    this.showApplyInfoPopup = false;
                    this.messageFn('success', '申请已提交,请等待审核');
                } else {
                    this.messageFn('error', res.data.msg);
                }
            })
        },
        openBuy(type){
            if(type === 'capacity'){
                this.$refs.payPopup.popupTitle = '容量不足';
                this.$refs.payPopup.popupTips = '当前容量已不足，无法继续新增知识，你可以购买永久容量包';
            } else {
                this.$refs.payPopup.popupTitle = '购买智能问答与写作次数包';
                this.$refs.payPopup.popupTips = '次数包有效期为365天';
                // if(this.combinationInfo.bigModel && !this.combinationInfo.canActivate){
                //     this.userBigModelActivate();
                // }
                if(!this.combinationInfo.bigModel  || (this.combinationInfo.bigModel && !this.combinationInfo.canActivate)){
                    this.messageFn('warning','暂无权限，请联系企业管理员开通权限')
                    return false;
                }
                
            }
            this.$emit('update:canUploadDiaVisible',false)
            this.showMorePopup = false;
            this.$refs.payPopup.popupType = type;
            this.promoteVisable = true;
        },
        // 用户大模型能力激活
        userBigModelActivate(){
            this.$http.get(requestUrl.company.userBigModelActivate + '?userId=' + localStorage.getItem('_uid')).then((res) => {
                if (res.data.code == 0) {
                    console.log(res);
                } else {
                    this.messageFn('error', res.data.message);
                }
            })
        },
        paySuccess(){
            this.updatePayStatus('');
            this.promoteVisable = false;
            // 获取最新的容量/流量信息
            this.updateVerInfo();
        },
        // 获取最新的 写作次数/次数包/容量
        updateVerInfo() {
            let param = {
                mainId: localStorage.getItem("_mainId"),
                userId: localStorage.getItem("_uid"),
                vipType:this.currentVersion.vipType,
                expire:this.currentVersion.expire,
                userSource:localStorage.getItem('accountSource')
            }
            this.$http.post(requestUrl.upload.getCapacity).then((res) => {
                if (res.data.code == 0) {
                    this.updateCombinationInfo(res.data.data);
                    this.$http.post(requestUrl.upload.getCount, param).then((res) => {
                        this.updateCombinationInfo(res.data);
                    });
                } else {
                    this.messageFn('error', res.data.message);
                }
            });
           
        },
        clickFlow(){
            if((!this.combinationInfo.bigModel  || (this.combinationInfo.bigModel && !this.combinationInfo.canActivate)) && this.currentVersion.vipType === 3){
                this.messageFn('warning','暂无权限，请联系企业管理员开通权限')
                return false
            }
        }
        
        // 
    }
}
</script>

<style lang="less" scoped>
.beyond-limit{
    .dialog-footer-can-use-popup{
        .popup-con-box{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .dialog-footer{
            display: flex;
            justify-content: center;
            align-items: center;
            .promote-version,.buy-times{
                padding: 0 15px;
                height: 30px;
                background: #CAD3F0;
                border-radius: 15px;
                background: linear-gradient(249deg, #5A7BE7, #8DABFF);
                color: #fff;
                cursor: pointer;
                text-align: center;
                line-height: 30px;
            }
            .buy-times{
                margin-left: 20px;
            }	
        }
    }
    .buy-style-popup{
        #lighting-popup-assembly{
            width: 500px !important;
        }
        .buy-style{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
            background: #F5F7FB;
            font-size: 22px;
            font-weight: bold;
            color: #366AFF;
            cursor: pointer;
            margin-bottom: 14px;
            &:nth-child(1){
                background: url(../../assets/images/buy-flow.png) no-repeat center/cover;
            }
            &:nth-child(2){
                background: url(../../assets/images/buy-capacity.png) no-repeat center/cover;
            }
             &:nth-child(3){
                background: url(../../assets/images/submit-apply.png) no-repeat center/cover;
            }
        }
    }
    .apply-info-popup{
        /deep/.el-form{
            .el-form-item{
                text-align: left;
                .tips{
                    color: #A9B3C6;
                    margin-left: 4px;
                    font-family: Microsoft YaHei;
                }
                .el-checkbox-group{
                    height: 40px;
                    background: #FBFCFD;
                    border-radius: 5px;
                    padding: 0 10px;
                }
                .el-textarea__inner{
                    height: 145px;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;
                    overflow-x: hidden;
                    overflow-y: auto;
                }
            }
        }
        .confirm-btn{
            color: #fff;
        }
    }
}
</style>