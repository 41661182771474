<template>
    <div class="lw-bot">
        <div v-show="withHeader" class="lw-bot-header">
            <div class="lw-bot-header-left">
                <span class="lbhl-icon">
                    <i class="iconfont guoran-askbotdandutubiao2"></i>
                </span>
                <span class="lbhl-name">AskBot智能助手</span>
            </div>
            <div class="lw-bot-header-right">
                <span @click="closeSearchBot" class="lbhr-close">
                    <i class="iconfont guoran-cuohao"></i>
                </span>
            </div>
        </div>
        <div class="lw-bot-body">
            <iframe height="100%" width="100%" :src="iframeSrc" frameborder="0"></iframe>
        </div>
         <!-- 此处在css 中更改了drawer父节点的样式，width 设置为了50%。所以组件的size 设置为100% -->
         <el-drawer :visible.sync="previewItem.visible" :destroy-on-close="true" direction="rtl" size="100%"
            :wrapperClosable="false" :modal="false" :withHeader="false">
            <div class="pre_title">
                <div class="close" @click="previewItem.visible = false">
                    <i class="iconfont guoran-tongyichicun-guanbi1"></i>
                </div>
                <div class="title">
                    {{ previewItem.name }}
                </div>
            </div>
            <div class="pre_cont" v-loading="previewItem.loading">
                <FilePreview ref="filePreview" :url="previewItem.href" v-if="previewItem.visible" :fileType="previewItem.fileType" :tagIds="previewItem.tagIds">
                </FilePreview>
            </div>
        </el-drawer>
        <!-- <ask-dialog @closeEvent="canUseAiDiaVisible = false" :popZindex="3333" v-if="canUseAiDiaVisible" :haveTips="true" :isNeedScroll="false" class="dialog-footer-can-use-popup">
            <div slot="popup-name">{{canUploadDialogTitle}}</div>
            <div slot="popup-tip">{{canUploadDialogTips}}</div>
            <div slot="popup-con" class="popup-con-box">
				<img height="300px" src="../../assets/images/uploadcanuse.png" alt="" srcset="">
            </div>
			<div slot="dialog-footer" class="dialog-footer">
				<div class="promote-version" v-if="currentVersion.vipType !== 1" @click="openPayVersion">升级版本</div>
				<div class="buy-times" v-if="currentVersion.vipType !== 0 && !currentVersion.expire" @click="openPayVersion">购买AI次数</div>
			</div>
        </ask-dialog> -->
        <beyondLimit
			:canUploadDiaVisible.sync="canUseAiDiaVisible"
			:canUploadDialogTitle="canUploadDialogTitle"
			:canUploadDialogTips="canUploadDialogTips"
            exceedObj="{type:'',beyondType:'capacity'}"
			:currentVersion="currentVersion"
            :showMorePopups.sync="showMorePopups"
            :showApplyInfoPopups.sync="showApplyInfoPopups">
		</beyondLimit>
    </div>
</template>
<script>
import FilePreview from './FilePreview.vue';
// import AskDialog from "./AskDialog.vue";
import { requestUrl } from '../../http/requestUrl';
import { mapActions,mapGetters, mapState } from 'vuex';
import beyondLimit from './beyondLimit.vue'
export default {
    name: "IndexView",
    components:{
        FilePreview,
        // AskDialog,
        beyondLimit
    },
    props: {
        // 是否展示头部
        withHeader: {
            type: Boolean,
            default: () => {
                return false
            }
        }
    },
    data() {
        return {
            iframeSrc: "",
            previewItem:{
                visible:false,
                loading:false,
                href:'',
                fileType: "",
                name: "",
                tagIds:""
            },
            canUseAiDiaVisible: false,
            // previewVisible:false,
            // previewLoading:false,
            // previewHref: '',
            // previewFileType: '',
            // knowledgeItem: null,
            canUploadDialogTitle:'',
            canUploadDialogTips:'',
            showMorePopups:false,
            showApplyInfoPopups:false,
            accountSource:null
        }
    },
     computed: {
        ...mapGetters('apphandle', {
            // 助手显隐
            isTopSearch: 'getIsTopSearch',
        }),
        // ...mapGetters('apphandle', {
        //     isTestSearch: 'getIsTestSearch',
        // }),
        ...mapGetters('apphandle', {
            isShowPreview: 'getIsShowPreview',
        }),
        ...mapGetters('user', {
            currentVersion: 'getCurrentVersion'
        }),
        ...mapState({
            // 智能写作次数/容量 套餐使用信息
            combinationInfo: state => state.user.combinationInfo,
        }),
    },
    methods: {
        openPayVersion(){
			this.canUseAiDiaVisible = false;
            this.$router.push({name:"payVersionView"})
        },
        handlerPopupContent(){
            //vipType   0：免费版，1：超级会员，2：高级会员
            // flowLackType  0是个人  1是企业
            if(this.currentVersion.vipType === 0 || this.currentVersion.expire){
                this.canUploadDialogTitle = '当前版本暂不支持AI能力'
                this.canUploadDialogTips = '您可以升级到高级会员或超级会员使用AI能力'
            } else if (this.currentVersion.vipType === 1){
                this.canUploadDialogTitle = '剩余智能问答与写作次数不足'
                this.canUploadDialogTips = '该功能需要消耗智能问答与写作次数，当前剩余次数不足您可以购买AI流量包'
            } else  if (this.currentVersion.vipType === 2) {
                this.canUploadDialogTitle = '剩余智能问答与写作次数不足'
                this.canUploadDialogTips = '该功能需要消耗智能问答与写作次数，当前剩余次数不足您可以升级版本或购买AI流量包'
            } else  if (this.currentVersion.vipType === 3) {
                this.canUploadDialogTitle = '剩余智能问答与写作次数不足'
                this.canUploadDialogTips = this.combinationInfo.flowLackType == 1 ? '该功能需要消耗智能问答与写作次数，当前企业剩余总次数不足' : '该功能需要消耗智能问答与写作次数，当前剩余次数不足'
            }
        },
        ...mapActions('apphandle', [
            'updateBotViewLoading'
        ]),
        // ...mapActions('apphandle', [
        //     'updateIsTestSearch'
        // ]),
        handleCopy() {
            const range = document.createRange();
            range.selectNode(document.getElementById('drag-upload-phone')); //获取复制内容的 id 选择器
            const selection = window.getSelection();  //创建 selection对象
            if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
            selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
            document.execCommand('copy'); //复制选中的文字到剪贴板
            this.messageFn('success', '复制成功');
            selection.removeRange(range); // 移除选中的元素
        },
        // 关闭机器人搜索展示
        closeSearchBot() {
            // if(this.isShowPreview){
                console.log(this.isShowPreview,55555);
                // if(this.isShowPreview){
                //     this.updateIsTestSearch(true)
                // } else {
                //     this.updateIsTestSearch(false)
                // }

                // this.updateIsTestSearch(this.isShowPreview)
            // }
            this.$emit("searchBotVisible", false);
        },
        // iframe 初始化
        bin2hex(str) {
            var result = "";
            for (let i = 0; i < str.length; i++) {
                result += this.int16_to_hex(str.charCodeAt(i));
            }
            return result;
        },
        int16_to_hex(i) {
            var result = i.toString(16);
            var j = 0;
            while (j + result.length < 4) {
                result = "0" + result;
                j++;
            }
            return result;
        },
        cans() {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            var txt = "http://security.tencent.com/";
            ctx.textBaseline = "top";
            ctx.font = "14px 'Arial'";
            // ctx!.textBaseline = 'tencent';
            ctx.fillStyle = "#f60";
            ctx.fillRect(125, 1, 62, 20);
            ctx.fillStyle = "#069";
            ctx.fillText(txt, 2, 15);
            ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
            ctx.fillText(txt, 4, 17);

            var b64 = canvas.toDataURL().replace("data:image/png;base64,", "");
            var bin = atob(b64);
            var crc = this.bin2hex(bin.slice(-16, -12));
            //var crc = bin.slice(-16,-12);
            return crc;
        },
        initIrameSrc(enterpriseId, data) {
            console.log('enterpriseId:', enterpriseId, data);

            window.__be = window.__be || {};
            // window.__be.id = "1417";
            // window.__be.apiKey = "02aedb94cdcf442696f4185b63b9e24c";
            // window.__be.secret = "4e4459314d5459304d7a45324d7a637a4d7a63774d773d3d";
            // window.__be.bindCode = "5bdc04b276b74eeea48321563b835231";

            window.__be.id = data.wechatBot.botId;
            window.__be.apiKey = data.wechatBot.apiKey;
            window.__be.secret = data.secret;
            window.__be.bindCode = data.wechatBot.bindCode;
            window.__be.agentId = data.wechatBot.agentId;
            window.__be.corpId = data.wechatBot.corpId;
            window.__be.thirdUserId = data.memberInDepartDto && data.memberInDepartDto.thirdUserId ? data.memberInDepartDto.thirdUserId : '';
            window.__be.isOhmPc = "pc";
            window.__be.sourceOhm = "ohm";
            window.__be.userId = localStorage.getItem("_uid") ? localStorage.getItem("_uid") : 'userName';

            window.__be.isAskLightning = "1"; // 知识管理 机器人客户端 标识 1 来源知识管理

            let thirdPartyUserId = window.__be.userId && window.__be.userId !== '' && window.__be.userId !== null && window.__be.userId != 'undefined' ? window.__be.userId : Math.round(Math.random() * 1000000);
            let baseUrl = "https://auth.wx.askbot.cn?botId=";
            if (location.host.indexOf("test") !== -1 || location.host.indexOf("localhost") !== -1) {
                baseUrl = "https://test.auth.wx.guoranbot.com?botId=";
            }
            this.iframeSrc = baseUrl +
                window.__be.id +
                "&_apiKey=" +
                window.__be.apiKey +
                "&agentId=" +
                window.__be.agentId +
                "&corpId=" +
                window.__be.corpId +
                "&isOhmPc=" +
                window.__be.isOhmPc +
                "&_secret=" +
                window.__be.secret +
                "&hostname=" +
                document.location.hostname +
                "&cans=" +
                this.cans() +
                "&bindCode=" +
                window.__be.bindCode +
                '&thirdPartyUserId=' + thirdPartyUserId +
                "&sourceOhm=" +
                window.__be.sourceOhm
                +
                "&isAskLightning=1" +
                "&isLookKnowledgeSummary=" + sessionStorage.getItem('isLookKnowledgeSummary') +
                "&knowledgeId=" + sessionStorage.getItem('knowledgeId') + '&isShowTips=' + 'true';

                if(this.accountSource == 11 || this.accountSource == 12){
                    this.iframeSrc += '&thirdUserId=' + window.__be.thirdUserId;
                }
                // if(!data.wechatBot.extInfo.enableKnowledgeSearch && !data.wechatBot.extInfo.enableBotRecognize){
                // if(!this.combinationInfo.bigModel){
                //     this.iframeSrc += '&isShowTips=' + 'true';
                // }
                console.log(this.iframeSrc,'iframeSrc');
                sessionStorage.removeItem('isLookKnowledgeSummary')
                sessionStorage.removeItem('knowledgeId');
                setTimeout(() => {
                    this.updateBotViewLoading(false);
                },2000)
        },
        getBotBindInfo(){
            this.$emit("getBotBindInfo", (data) => {
                this.initIrameSrc('activeEnterpriseId', data);
            });
        },
        creatOrRemoveMessgaeListener(flag) {
            if(flag) {
                window.addEventListener('message', (e) =>{
                    // 机器人搜索是否触发 次数限制提示
                    if (e.data.type === "fromBot") {
                        // 初次没有次数 1     其他 2
                        if(e.data.data == 1){
                            this.canUseAiDiaVisible = true;
                            this.handlerPopupContent();
                        }
                    }
                    if(e.data.type == 'webpackwarning') {
                        return
                    }
                    let data = e.data
                    if(data.data == 'bot_preview'){
                        this.previewItem.visible = false;
                        let url = data.url
                        let item = JSON.parse(data.item)
                        this.previewItem.name = item.from
                        console.log(item,'item');
                        this.previewItem.loading = true
                        let index = url.lastIndexOf('?')
                        let type = ''
                        let httpUrl = requestUrl.preview.previewUrl
                        if (index !== -1) {
                            let newUrl = url.substring(0, index)
                            type = this.fileType(newUrl)
                        } else {
                            type = this.fileType(url)
                        }
                        if (type === '' || type === 'OTHER') {
                            httpUrl = httpUrl += '?needEncrypt=true'
                        } else {
                            httpUrl = httpUrl += '?needEncrypt=false'
                        }
                        this.$http.post(httpUrl, {
                            fileInOssPath: url,
                        }).then(response => {
                            if (response.data.code == 0) {
                                this.previewItem.visible = true;
                                this.handlerPosition();
                                this.previewItem.href = response.data.data
                                this.previewItem.fileType = type
                                if(item.tagId) {
                                    this.previewItem.tagIds = item.tagIds
                                } else {
                                    this.previewItem.tagIds = []
                                }
                                // this.previewItem.tagIds = ["643fcec4b0f72141997fb9e9","643fcec3b0f72141997fb9e7","643fcec3b0f72141997fb9e4","643fcec3b0f72141997fb9e5","643fcec3b0f72141997fb9e1"]
                                this.previewItem.loading = false
                                this.$nextTick(() =>{
                                    this.$refs.filePreview.source = ''
                                    if(item.source == "CREATED_BY_ONESELF" || type === 'HTML') {
                                        this.$refs.filePreview.getCkeditorContent(url)
                                    }
                                    // else if(type === 'HTML') {
                                    //     this.$refs.filePreview.getCkeditorContent(url)
                                    // }
                                })
                            }
                        })

                    }
                })
            } else {
                window.removeEventListener('message', () => {})
            }
        },
        fileType(url) {
            const fileType = url.substring(url.lastIndexOf('.'));
            if (fileType === '.mp4' || fileType === '.MP4' || fileType === '.MOV' || fileType === '.MOVC' || fileType === '.mov' || fileType === '.movc') {
                return 'VIDEO'
            } else if (fileType === '.html') {
                return 'HTML'
            } else {
                return 'OTHER'
            }
        },
        handlerPosition(){
            let domList = Array.from(document.querySelectorAll('.el-drawer__wrapper'));
            domList[0].style.display = 'none';
            // console.log(this.isTestSearch,this.isTopSearch,1111111,domList[1]);
            if(this.isTopSearch){
                domList[1].style.right = '390px';
            } else {
                domList[1].style.right = '0';
            }
        },
    },
    mounted() {
        this.accountSource = Number(localStorage.getItem('accountSource'));
        console.log(this.withHeader);
        this.getBotBindInfo();
        this.creatOrRemoveMessgaeListener(true);

    },
    beforeDestroy() {
        this.creatOrRemoveMessgaeListener(false)
    }
}
</script>
<style lang="less" scoped>
@import "./../../assets/less/common.less";
.drag-upload-phone {
    margin: 50px auto 0;
    width: 240px;
    height: 31px;
    line-height: 31px;
    color: white;
    background: #366AFF;
    border-radius: 15px;
    cursor: pointer;
}
.lw-bot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .lw-bot-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        background: #FFFFFF;
        border-radius: 5px 5px 0px 0px;

        .lw-bot-header-left {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            margin-left: 12px;
            height: 100%;
            align-items: center;
            color: @system_ask_color;

            .lbhl-icon {
                i {
                    font-size: 24px;
                }
            }

            .lbhl-name {
                margin-left: 6px;
                font-weight: bold;
            }
        }

        .lw-bot-header-right {
            flex: none;
            width: 50px;

            .lbhr-close {
                cursor: pointer;
                color: @system_ask_color;

                i {
                    font-size: 18px;
                }
            }
        }
    }

    .lw-bot-body {
        flex: auto;
        width: 100%;
    }

    .dialog-footer-can-use-popup{
		.popup-con-box{
			width: 100%;
			display: flex;
			justify-content: center;
		}
		.dialog-footer{
			display: flex;
			justify-content: center;
			align-items: center;
			.promote-version,.buy-times{
				padding: 0 15px;
				height: 30px;
				background: #CAD3F0;
				border-radius: 15px;
				background: linear-gradient(249deg, #5A7BE7, #8DABFF);
				color: #fff;
				cursor: pointer;
				text-align: center;
				line-height: 30px;
			}
			.buy-times{
				margin-left: 20px;
			}
		}
	}
}

.el-drawer__wrapper {
    width: 700px;
    // left: calc(100vw - 700px);
    // right: 700px;
    overflow: visible;
    left: inherit;

    /deep/.el-drawer__body {
        height: 100vh;
    }
}

.pre_title {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 10px;

    .iconfont {
        cursor: pointer;
    }

    .close {
        width: 20px;
        margin-right: 15px;

        i {
            font-size: 12px;
        }
    }

    .title {
        width: calc(100% - 35px);
        text-align: left;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.pre_cont {
    width: 100%;
    height: calc(100vh - 40px);
}
</style>
