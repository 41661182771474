<template>
    <div v-if="inviteFriendsNoticeVisible" :class="['invite-friends-notice',appNoticeVisible ? 'max-t' : '',!uploadInfoVisible ? 'animation-min' : '',isTopSearch ? 'max-r' : 'min-r']">
        <transition name="el-zoom-in-top" v-for="(item,index) in inviteFriendsList" :key="index">
            <div class="item" >
                <div class="knowledge-summary-notice-header">
                    <div class="aunh-left">{{item.time}}</div>
                    <div class="aunh-right">
                        <span  class="aunh-right-close"  @click="changeInviteFriendsNotice(item,index)">
                            <i class="iconfont guoran-cuohao2"></i>
                        </span>
                    </div>
                </div>
                <div class="knowledge-summary-notice-content">{{item.handleResult.tip}}</div>
            </div>
        </transition>
    </div>
</template>
<script>
import { requestUrl } from "../../http/requestUrl";
import { mapGetters, mapActions } from 'vuex';
export default {
    name: "InviteFriendsNotice",
    data() {
        return {
            inviteFriendsList: [],
            // inviteFriendsNoticeVisible:false,
        }
    },
    props:{
        inviteFriendsNoticeVisible:{
            type:Boolean,
            default:false
        },
        appNoticeVisible:{
            type:Boolean,
            default:false
        }
    },
    watch: {

    },
    computed:{
        ...mapGetters('apphandle', {
            // 微信同步弹框显隐
            uploadInfoVisible: 'getUploadInfoVisible',
        }),
        ...mapGetters('apphandle', {
            // 助手显隐
            isTopSearch: 'getIsTopSearch',
        }),
    },
    mounted() {
        // this.inviteFriendsList = ['哈哈','嘻嘻','呜呜'];
    },
    methods:{
        ...mapActions('apphandle', [
            'updateBotViewLoading'
        ]),
        // 获取邀请好友notice列表
        getInviteFriendsNoticeList(eventData){
            let ids = [];
            if(eventData && eventData.payload && eventData.payload.recordId){
                ids.push(eventData.payload.recordId);
                this.inviteFriendsList = [...this.inviteFriendsList,...[{
                    id:eventData.payload.recordId,
                    time: this.getDataTime(eventData.createTime),
                    handleResult:{
                        buyVipRecordId:eventData.payload.recordId,
                        tip:eventData.payload.tip,
                        hasMoreGift:eventData.payload.hasMoreGift,
                        inviteCount:eventData.payload.inviteCount,
                    }
                }]];
                this.markInvite(ids);
                this.$emit('changeInviteNoticeVisible',this.inviteFriendsList.length === 0 ? false : true);
            } else {
                this.$http.get(requestUrl.invite.getInviteList + "?client=web").then((res) => {
                    if (res.data.code === "0") {
                        if(res.data.data && res.data.data.length > 0 ){
                            res.data.data.forEach(item => {
                                ids.push(item.id);
                                item.time = this.getDataTime(item.handleTime);
                                this.inviteFriendsList.push(item)
                            })
                            this.markInvite(ids);
                        }
                        this.$emit('changeInviteNoticeVisible',this.inviteFriendsList.length === 0 ? false : true);
                    } else {
                        this.messageFn('error', res.message);
                    }
                });
            }
            
            
        },
        // 时间戳转换成时间
		getDataTime(times){
			if (!times) {
				return '-'
			}
			let getBYT = (num) => {
				if(num == 0) {
					return '00'
				} else if(num < 10) {
					return '0' + num;
				} else {
					return num;
				}
			}
			let date = new Date(times);
            let H = getBYT(date.getHours() )+ ' '
            let M = getBYT(date.getMinutes() )+ ' '
            return H + '时 ' + M + '分'
		},
          // 标记摘要记录
        markInvite(ids){
            this.$http.post(requestUrl.invite.markInvite,{
                list:ids,
                client: "web"
            }).then((res) => {
                if (res.data.code === "0") {
                    console.log('markKnowledgeSummary',res);
                }
            })
        },
        // 关闭notice
        changeInviteFriendsNotice(item,index) {
            this.inviteFriendsList.splice(index,1);
            this.$emit('changeInviteNoticeVisible',this.inviteFriendsList.length === 0 ? false : true);

        },
    }
}
</script>
<style lang="less" scoped>
@import "./../../assets/less/common.less";
.invite-friends-notice {
    z-index: 2100;
    position: absolute;
    min-height: 60px;
    top: 18px;
    right: 3px;
    max-height: calc(100% - 20px);
    overflow-x: hidden;
    overflow-y: auto;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // box-shadow: 0 1px 9px 0 rgba(57,63,79,.21);

    &.max-t{
        top: 125px;
    }
    &.animation-min{
        animation: identifier 1s ease 1; 
        animation-fill-mode: forwards;/*定义动画结束的状态*/
    }
    &.max-r{
        right: 393px;
    }
    &.min-r{
        right: 3px;
    }

    @keyframes identifier {
        0%{top:125px}
        100%{top:18px}
    }
    
    .item{
        margin-bottom: 10px;
        background: #FFFFFF;
        box-shadow:0 1px 10px 0 rgba(57,63,79,.31);
        border-radius: 5px;
        padding:0 15px;
        width: 250px;
        font-size: 12px;
        &:first-child{
            margin-top: 10px;
        }
    }

    .knowledge-summary-notice-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        line-height: 50px;

        .aunh-left {
            flex: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;
            line-height: 50px;
            color: #A9B3C6;
            font-size: 12px;

        }

        .aunh-right {
            flex: none;
            width: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .aunh-right-close {
                margin-left: 6px;
                color: #A9B3C6;
                cursor: pointer;
            }
        }
    }

    .knowledge-summary-notice-content {
        color: #2c3e50;
        font-size: 14px;
        text-align: left;
        padding-bottom: 16px;

        span{
            color: #366AFF;
            cursor: pointer;
            // white-space: nowrap;
        }
    }
}
</style>
  