const compareWidth = (el) => {
    console.log(el.querySelector('.ellipse-text'),'elelelel');

    // 如果没有超出宽度，即子<父 则移除tooltip
    if (el.querySelector('.ellipse-text') && el.querySelector('.ellipse-text').offsetWidth < el.offsetWidth) {
        
        const copyEl = el.parentNode; // 获取到目标节点的父节点
        const copySpan = el.querySelector('.ellipse-text'); // 获取到目标节点的子节点，即纯粹的span标签文案
        el.parentNode.removeChild(el); // 移除带有el-tooltip组件的节点
        copyEl.appendChild(copySpan); // 将纯粹的span标签文案整体追加到目标节点的父节点
    }
};
export default compareWidth