import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/MainView.vue'
// import { GetQueryString } from "@/common/common";
/**
 * 重写路由的push方法
 * 解决:vue在3.0以上的版本中的错误提示，不允许从当前路由跳转到当前路由
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  // 这个语句用来解决报错 调用原来的push函数，并捕获异常
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'AuthView',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/AuthView.vue')
  },
  {
    path: '/login',
    name: 'AuthViewInner',
    component: () => import(/* webpackChunkName: "about" */ './../views/login/AuthView.vue')
  },
  {
    path:'/newAuth',
    name:"newAuth",
    component: () => import(/* webpackChunkName: "about" */ './../views/newVersionView/newAuth')
  },
  {
    path: '/',
    name: 'main',
    component: Main,
    redirect: '/searchKnowledge', // 设置默认打开的页面
    children: [
      // {
      //   path: '/auth',
      //   name: 'AuthView',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/auth/AuthView.vue')
      // },

      {
        path: 'template',
        name: 'TemplateView',
        component: () => import(/* webpackChunkName: "about" */ '../views/template/TemplateView.vue')
      },
      // {
      //   path: '/login',
      //   name: 'LoginView',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/inviteEnterLogin/login.vue')
      // },
      {
        path: 'bot',
        name: 'BotView',
        component: () => import(/* webpackChunkName: "about" */ '../views/testComponents/BotView.vue')
      },
      {
        path: 'assistant',
        name: 'IntelligentAssistant',
        component: () => import(/* webpackChunkName: "about" */ '../views/assistant/IntelligentAssistant.vue')
      },


      {
        path: 'staffDirectoryView',
        name: 'staffDirectoryView',
        component: () => import(/* webpackChunkName: "about" */ '../views/staffDirectoryView/staffDirectoryView.vue')
      },
      {
        path: 'payVersion',
        name: 'payVersionView',
        component: () => import(/* webpackChunkName: "about" */ '../views/payVersion/payVersionView')
      },
      {
        path: 'joinCompany',
        name: 'joinCompany',
        component: () => import(/* webpackChunkName: "about" */ '../views/joinCompany/joinCompany')
      },
      {
        path:"NewsIndex",
        name:"NewsIndex",
        component: () => import("../views/newsAnnouncement/NewsIndex")
      },
      {
        path:"toDoList",
        name:"toDoList",
        component: () => import("../views/toDo/index")
      },
      {
        path:'/todoItem',
        name:'todoItem',
        component: () => import(/* webpackChunkName: "about" */ '../views/toDo/listItem')
      },
      {
        path:'/searchKnowledge',
        name:'searchKnowledge',
        component: () => import(/* webpackChunkName: "about" */ '../views/search/searchView')
      },
      {
        path:'/knowledgeView',
        name:'knowledgeView',
        component: () => import(/* webpackChunkName: "about" */ '../views/newVersionView/knowledge/knowledgeView'),
        children:[
          {
            path: 'index',
            name: 'IndexView',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/index/IndexView.vue')
          },
          {
            path: 'enterpriseKnowledgeView',
            name: 'enterpriseKnowledgeView',
            component: () => import(/* webpackChunkName: "about" */ '../views/enterpriseknowledge/enterpriseKnowledgeView')
          },
        ]
      },
      {
        path:"workOrderView",
        name:"workOrderView",
        component: () => import('../views/workOrder/index')
      },
      {
        path: 'recycleBin',
        name: 'recycleBinView',
        component: () => import(/* webpackChunkName: "about" */ '../views/recycleBin/recycleBinView')
      },
      {
        path: 'emptyPage',
        name: 'emptyPage',
        component: () => import(/* webpackChunkName: "about" */ '../views/newVersionView/emptyPage')
      },
    ]
  },
  {
    path: "/versionManage",
    name: "versionManage",
    component: () => import('../views/versionManage/versionManage')
  },
  {
    path:'/originSearchKnowledge',
    name:'originSearchKnowledge',
    component: () => import(/* webpackChunkName: "about" */ '../views/search/originSearchKnowledge')
  },
  {
    path: '/weboffice',
    name: 'WebOfficePage',
    component: () => import(/* webpackChunkName: "about" */ '../views/versionManage/webOfficePage.vue'),
    props: route => ({
      officeType: route.query.officeType,
      fileId: route.query.fileId,
      userId: route.query.userId,
    }),
  },
  {
    path:'/aippt',
    name:'aippt',
    component: () => import(/* webpackChunkName: "about" */ '../views/aiPPT/index.vue')
  },
  {
    path:'/pptRenderer',
    name:'PPTRenderer',
    component: () => import(/* webpackChunkName: "about" */ '../views/aiPPT/components/pptRenderer.vue')
  },
  {
    path:'/templateEditor',
    name:'TemplateEditor',
    component: () => import(/* webpackChunkName: "about" */ '../views/aiPPT/components/templateEditor.vue')
  },
  {
    path:'/templateSelector',
    name:'TemplateSelector',
    component: () => import(/* webpackChunkName: "about" */ '../views/aiPPT/components/templateSelector.vue')
  },
]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from,  next) => {
  let token = localStorage.getItem('_token')
  console.log(to, from, token, next,'type 154');
  if(!token) {
    if(to.path == '/versionManage') {
      // 校验链接参数是否完整，如果无链接参数，那么允许跳转，并在 versionManage 页面校验无内容
      if(to.query) {
        sessionStorage.setItem('versionManageDB', JSON.stringify({...to.query}))
        if(to.query.outside === true || to.query.outside === 'true') {
          next()
        } else {
          next('/login')
        }
      } else {
        next()
      }
      return
    }
    if (document.referrer.includes('wx.askbot.cn')){
      if(to.path == '/newAuth' || to.path == '/searchKnowledge'){
        next()
      } else {
        next()
      }
      return;
    }

    if(to.path == '/login' || to.path == '/auth' || to.path == '/todoItem') {
      next()
    } else {
      console.log('====')
      next('/login')
    }
  } else {
    // if (from.path === '/versionManage' && from.query){
    //   // sessionStorage.setItem('versionManageDB', JSON.stringify({...from.query}))
    // }
    // if (GetQueryString(window.location.search,'type')){
    //   next('/newAuth')
    //   return;
    // }
    if (to.path !== '/versionManage' && from.path !== '/versionManage'){
      sessionStorage.removeItem('selectTab')
    }
    next()
  }
})

export default router
