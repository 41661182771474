<template>
    <div v-if="false" :class="['knowledge-summary-notice',appNoticeVisible ? 'max-t' : '',!uploadInfoVisible ? 'animation-min' : '',isTopSearch ? 'max-r' : 'min-r']">
        <transition name="el-zoom-in-top" v-for="(item,index) in knowledgeSummaryList" :key="item.id">
            <div class="item" >
                <div class="knowledge-summary-notice-header">
                    <div class="aunh-left">{{item.time}}</div>
                    <div class="aunh-right">
                        <span  class="aunh-right-close"  @click="changeKnowledgeSummaryNotice('close',item,index,false)">
                            <i class="iconfont guoran-cuohao2"></i>
                        </span>
                    </div>
                </div>
                <div class="knowledge-summary-notice-content">
                    AskBot为您收集的微信文章《{{item.knowledgeName}}》总结并生成了要点，
                    <span @click="changeKnowledgeSummaryNotice('look',item,index,false)">点击查看</span>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { requestUrl } from "../../http/requestUrl";
import { mapGetters, mapActions } from 'vuex';
export default {
    name: "KnowledgeSummaryNotice",
    data() {
        return {
            // knowledgeSummaryNoticeVisible: true,
            knowledgeSummaryList: [],
        }
    },
    props:{
        knowledgeSummaryNoticeVisible:{
            type:Boolean,
            default:false
        },
        appNoticeVisible:{
            type:Boolean,
            default:false
        }
    },
    watch: {

    },
    computed:{
        ...mapGetters('apphandle', {
            // 助手显隐
            isTopSearch: 'getIsTopSearch',
        }),
        ...mapGetters('apphandle', {
            // 微信同步弹框显隐
            uploadInfoVisible: 'getUploadInfoVisible',
        }),
    },
    mounted() {
        this.knowledgeSummaryList = [];
    },
    methods:{
        ...mapActions('apphandle', [
            'updateBotViewLoading'
        ]),
        // 获取知识总结列表
        getKnowledgeSummaryNoticeList(eventData){
            let ids = [];
            if(eventData && eventData.payload && eventData.payload.recordId){
                ids.push(eventData.payload.recordId);
                this.knowledgeSummaryList = [...this.knowledgeSummaryList,...[{
                    id:eventData.payload.recordId,
                    knowledgeId:eventData.payload.knowledgeId,
                    knowledgeName:eventData.payload.knowledgeName,
                    handleType:'summary',
                    time: this.getDataTime(eventData.createTime)
                }]];
                this.markSummary(ids);
                this.$emit('changeKnowledgeSummaryNoticeVisible',this.knowledgeSummaryList.length === 0 ? false : true);
            } else {
                this.$http.get(requestUrl.upload.getKnowledgeSummaryNoticeList + "?client=web").then((res) => {
                    if (res.data.code === "0") {
                        // this.knowledgeSummaryList = [...this.knowledgeSummaryList,...res.data.data];
                        this.$emit('changeKnowledgeSummaryNoticeVisible',this.knowledgeSummaryList.length === 0 ? false : true);
                        if(res.data.data && res.data.data.length > 0 ){
                            res.data.data.forEach(item => {
                                ids.push(item.id);
                                item.time = this.getDataTime(item.updateTime);
                                this.knowledgeSummaryList.push(item)
                            })
                            this.markSummary(ids);
                        }
                        this.$emit('changeKnowledgeSummaryNoticeVisible',this.knowledgeSummaryList.length === 0 ? false : true);
                    }
                });
            }
        },
        // 时间戳转换成时间
		getDataTime(times){
			if (!times) {
				return '-'
			}
			let getBYT = (num) => {
				if(num == 0) {
					return '00'
				} else if(num < 10) {
					return '0' + num;
				} else {
					return num;
				}
			}
			let date = new Date(times);
            let m = getBYT(date.getMonth() + 1 )
            let D = getBYT(date.getDate() )
            let H = getBYT(date.getHours() )
            let M = getBYT(date.getMinutes() );
            if (date.toDateString() === new Date().toDateString()) {
                return H + '时' + M + '分'
            } else if (date < new Date()){
                return m + '月' + D + '日' + " " +  H + '时' + M + '分'
            }
		},
        // 标记摘要记录
        markSummary(ids){
            this.$http.post(requestUrl.upload.markKnowledgeSummary,{
                list:ids,
                client: "web"
            }).then((res) => {
                if (res.data.code === "0") {
                    console.log('markKnowledgeSummary',res);
                }
            })
        },
        // 触发机器人搜索展示
        changeKnowledgeSummaryNotice(type,item,index,flag) {
            this.$http.put(requestUrl.upload.viewKnowledge + item.id + '?client=web').then((res) => {
                if (res.data.code === "0") {
                    if(type === 'look'){
                        sessionStorage.setItem('isLookKnowledgeSummary',1);
                        sessionStorage.setItem('knowledgeId',item.knowledgeId);
                        if(this.isTopSearch || this.$route.path === '/assistant'){
                            this.updateBotViewLoading(true);
                        } else {
                            this.$emit('searchBotVisible',true);
                        }
                    }
                    this.knowledgeSummaryList.splice(index,1);
                    if(this.knowledgeSummaryList.length === 0){
                        this.$emit('changeKnowledgeSummaryNoticeVisible',flag);
                    } 
                }
            });
        },
    }
}
</script>
<style lang="less" scoped>
@import "./../../assets/less/common.less";

.knowledge-summary-notice {
    z-index: 2100;
    position: absolute;
    min-height: 60px;
    top: 18px;
    right: 3px;
    max-height: calc(100% - 20px);
    overflow-x: hidden;
    overflow-y: auto;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // box-shadow: 0 1px 9px 0 rgba(57,63,79,.21);

    &.max-t{
        top: 125px;
    }
    &.animation-min{
        animation: identifier 1s ease 1; 
        animation-fill-mode: forwards;/*定义动画结束的状态*/
    }
    @keyframes identifier {
        0%{top:125px}
        100%{top:18px}
    }
    &.max-r{
        right: 393px;
    }
    &.min-r{
        right: 3px;
    }
    
    .item{
        margin-bottom: 10px;
        background: #FFFFFF;
        box-shadow:0 1px 10px 0 rgba(57,63,79,.31);
        border-radius: 5px;
        padding:0 15px;
        width: 250px;
        font-size: 12px;
        &:first-child{
            margin-top: 10px;
        }
    }

    .knowledge-summary-notice-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        line-height: 50px;

        .aunh-left {
            flex: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;
            line-height: 50px;
            color: #A9B3C6;
            font-size: 12px;

        }

        .aunh-right {
            flex: none;
            width: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .aunh-right-close {
                margin-left: 6px;
                color: #A9B3C6;
                cursor: pointer;
            }
        }
    }

    .knowledge-summary-notice-content {
        color: #2c3e50;
        font-size: 14px;
        text-align: left;
        padding-bottom: 16px;

        span{
            color: #366AFF;
            cursor: pointer;
            // white-space: nowrap;
        }
    }
}
</style>
  