
import { requestUrl } from "./../../http/requestUrl";
const state = () => ({
    // 套餐信息 智能写作/容量
    combinationInfo: {
        capacityUsed: "",
        capacityTotal: "",
        capacitySurplus: "",
        flowUsed: "",
        flowTotal: "",
        flowSurplus: ""
    },
    // 模板列表
    modeList: [],
    // 从快捷创建 正在使用的模板
    quickCreateModeId: "",
    // 左侧导航 点击模板 筛选知识列表 当前模板id
    searchDocModeId: "",
    // 最后一次点击左侧首页导航时间戳
    lastClickLNavTamp: "",
    currentVersion: {}, // 当前版本信息
    payStatus: '', // 'success' 成功   'fail' 失败   '' 初始值
    isNeedSaveTemplate: false, // 是否需要保存模版数据
    isLoginOut: false,
})

const getters = {
    navModeList: (state) => {
        let navModeList = [], moreModeList = [];

        // 模板不超过10个 全展示在nav 超过10个显示更多
        if (state.modeList.length > 10) {
            moreModeList = state.modeList.slice(5);
            navModeList = state.modeList.slice(0, 5);
        } else {
            navModeList = state.modeList;
        }
        return {
            navModeList,
            moreModeList
        }
    },

    totalModeList: (state) => {
        return state.modeList;
    },

    activeQuickCreateModeId: (state) => {
        return state.quickCreateModeId;
    },

    activeSearchDocModeId: (state) => {
        return state.searchDocModeId;
    },
    cLastClickLNavTamp: (state) => {
        return state.lastClickLNavTamp;
    },
    getCurrentVersion: (state) => {
        // state.currentVersion.vipType = 3
        return state.currentVersion;
    },
    getPayStatus: (state) => {
        return state.payStatus;
    },
    getIsNeedSaveTemplate: (state) => {
        return state.isNeedSaveTemplate;
    },
    getIsLoginOut: (state) => {
        return state.isLoginOut;
    },
    
}

const actions = {
    updateUserModeInfo({ commit }, userId) {
        console.log(userId);
        // 模拟异步接口请求 用户模板list
        // userid 用户id
        // setTimeout(() => {
        //     commit('setUserModeList', [
        //         { name: '日报', icon: 'iconfont guoran-ribao', id: 'daily', color: '#458FFF' },
        //         { name: '周报', icon: 'iconfont guoran-zhoubao', id: "weekly", color: '#3CD781' },
        //         { name: '月报', icon: 'iconfont guoran-yuebao', id: "monthly", color: '#7367F0' },
        //         { name: '会议纪要', icon: 'iconfont guoran-huiyijiyao', id: 'meetingMinutes', color: '#FFBF6B' },
        //         { name: '项目记录', icon: 'iconfont guoran-xiangmujilu', id: 'projectRecords', color: '#AFA8F8' },
        //         { name: '市场分析报告', icon: 'iconfont guoran-tongyichicun-fenxibaogao', id: 'marketAnalysis', color: '#00C2BB' },
        //     ])

        // }, 800);

        this._vm.$http
            .get(`${requestUrl.template.getTemplateList}?page=0&size=100&withContent=false`)
            .then((res) => {
                if (res.data.code == 0) {
                    commit('setUserModeList', res.data.data.content.map((value) => {
                        if (value.name !== null) {
                            return value;
                        } else {
                            value.name = "";
                            return value;
                        }
                    }));
                } else {
                    console.log(res.data.msg);
                    // this._vm.$message.error(res.data.msg);
                }
            });
    },
    updateCombinationInfo({ commit }, fileCapacityInfo) {
        commit("setCombinationInfo", fileCapacityInfo);
    },
    updateQuickCreateModeId({ commit }, templateId) {
        commit("setQuickCreateModeId", templateId);
    },
    updateSearchDocModeId({ commit }, templateId) {
        commit("setSearchDocModeId", templateId);
    },
    updateLastClickLNavTamp({ commit }, tamp) {
        commit("setLastClickLNavTamp", tamp);
    },
    updateCurrentVersionInfo({ commit }, currentVersion) {
        commit("setCurrentVersionInfo", currentVersion);
    },
    updatePayStatus({ commit }, payStatus) {
        commit("setPayStatus", payStatus);
    },
    updateIsNeedSaveTemplate({ commit }, isNeedSaveTemplate) {
        commit("setIsNeedSaveTemplate", isNeedSaveTemplate);
    },
    updateIsLoginOut({ commit }, isLoginOut) {
        commit("setIsLoginOut", isLoginOut);
    },
    
}

const mutations = {
    setUserModeList(state, modeList) {
        state.modeList = [...modeList];
    },
    setCombinationInfo(state, combinationInfo) {
        if (combinationInfo.type == 'fileCapacity') {
            state.combinationInfo = {
                capacityUsed: combinationInfo.capacityUsed,
                capacityTotal: combinationInfo.capacityTotal,
                capacitySurplus: combinationInfo.capacitySurplus,
                companyCapacityUsed:combinationInfo.companyCapacityUsed,
                companyCapacitySurplus: combinationInfo.companyCapacitySurplus,
                capacityPercentage: Number((((combinationInfo.capacityUsed + combinationInfo.companyCapacityUsed) / combinationInfo.capacityTotal.toFixed(1)) * 100).toFixed(1)),
                earliestExpireFlowCount: state.combinationInfo.earliestExpireFlowCount,
                earliestExpireTime: state.combinationInfo.earliestExpireTime,
                withinAmonth:state.combinationInfo.withinAmonth,
                flowUsed: state.combinationInfo.flowUsed,
                flowTotal: state.combinationInfo.flowTotal,
                flowSurplus: state.combinationInfo.flowSurplus,
                flowPercentage: state.combinationInfo.flowPercentage,
                flowUsedText: state.combinationInfo.flowUsedText,
                flowTotalText: state.combinationInfo.flowTotalText,
                flowPackageTotal: state.combinationInfo.flowPackageTotal || 0,
                companyFlowMonthRefresh:state.combinationInfo.companyFlowMonthRefresh,
                bigModel: state.combinationInfo.bigModel,
                expire: state.combinationInfo.expire,
                canActivate: state.combinationInfo.canActivate,
                flowLackType:state.combinationInfo.flowLackType,
            }
        }
        if (combinationInfo.type == 'flowSurplus') {
            if (combinationInfo.flowUsed / 10000 > 1) {
                combinationInfo.flowUsedText = (combinationInfo.flowUsed / 10000).toFixed(1) + 'w';
            } else if (combinationInfo.flowUsed / 1000 > 1) {
                combinationInfo.flowUsedText = (combinationInfo.flowUsed / 1000).toFixed(1) + 'k'
            } else { 
                combinationInfo.flowUsedText = combinationInfo.flowUsed
            }
            // -3 不可用
            if (combinationInfo.flowTotal == -3) {
                combinationInfo.flowTotalText = 0;
                combinationInfo.flowPercentage = 100;
            } else { 
                combinationInfo.flowPercentage = Number((combinationInfo.flowUsed/combinationInfo.flowTotal*100).toFixed(1)) > 100 ? 100 : Number((combinationInfo.flowUsed/combinationInfo.flowTotal*100).toFixed(1))
                if (combinationInfo.flowTotal / 10000 > 1) {
                    combinationInfo.flowTotalText = (combinationInfo.flowTotal / 10000).toFixed(1) + 'w';
                } else if (combinationInfo.flowTotal / 1000 > 1) {
                    combinationInfo.flowTotalText = (combinationInfo.flowTotal / 1000).toFixed(1) + 'k'
                } else { 
                    combinationInfo.flowTotalText = combinationInfo.flowTotal
                }
            }
            let year = new Date().getFullYear();
            let month = new Date().getMonth() + 1; //获取当前月
            let date = new Date().getDate(); //获取当前日
            if (month < 10) {
                month = '0' + month
            }
            if (date < 10) {
                date = '0' + date
            }
            let nowTime = new Date(year + '-' + month + "-" + date).getTime();
            let earliestExpireTime = new Date(combinationInfo.earliestExpireTime).getTime();
            // combinationInfo.withinAmonth = false;
            // console.log(1000 * 60 * 60 * 24 * 30,year + '-' + month + "-" + date);
            // console.log(nowTime, 'nowTime');
            // console.log(earliestExpireTime, 'earliestExpireTime');
            // console.log(earliestExpireTime - nowTime,'差值');
            if (earliestExpireTime - nowTime <= 1000 * 60 * 60 * 24 * 31) {
                // console.log('一个月之内');
                combinationInfo.withinAmonth = true;
            }
            
            state.combinationInfo = {
                capacityUsed: state.combinationInfo.capacityUsed,
                capacityTotal: state.combinationInfo.capacityTotal,
                capacitySurplus: state.combinationInfo.capacitySurplus,
                companyCapacityUsed:state.combinationInfo.companyCapacityUsed,
                companyCapacitySurplus: state.combinationInfo.companyCapacitySurplus,
                capacityPercentage: state.combinationInfo.capacityPercentage,
                earliestExpireFlowCount: combinationInfo.earliestExpireFlowCount,
                earliestExpireTime: combinationInfo.earliestExpireTime,
                withinAmonth:combinationInfo.withinAmonth,
                flowUsed: combinationInfo.flowUsed,
                flowTotal: combinationInfo.flowTotal,
                flowPercentage: combinationInfo.flowPercentage,
                flowSurplus: combinationInfo.flowSurplus,
                flowUsedText: combinationInfo.flowUsedText,
                flowTotalText: combinationInfo.flowTotalText,
                flowPackageTotal: combinationInfo.flowPackageTotal  || 0,
                companyFlowMonthRefresh: combinationInfo.companyFlowMonthRefresh,
                bigModel: combinationInfo.bigModel,
                expire: combinationInfo.expire,
                canActivate: combinationInfo.canActivate,
                flowLackType:combinationInfo.flowLackType,
            }
        }
    },
    setQuickCreateModeId(state, templateId) {
        state.quickCreateModeId = templateId;
    },
    setSearchDocModeId(state, templateId) {
        console.log(templateId);
        state.searchDocModeId = templateId;
    },
    setLastClickLNavTamp(state, tamp) {
        state.lastClickLNavTamp = tamp;
    },
    setCurrentVersionInfo(state, info) {
        state.currentVersion = info;
    },
    setPayStatus(state, payStatus) {
        state.payStatus = payStatus;
    },
    setIsNeedSaveTemplate(state, isNeedSaveTemplate) {
        state.isNeedSaveTemplate = isNeedSaveTemplate;
    },
    setIsLoginOut(state, isLoginOut) {
        state.isLoginOut = isLoginOut;
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}