<template>
    <ww-open-data :type="type" :openid="openid" />
</template>

<script>
    export default {
        name: "openData",
        props:["type","openid"],
        created() {
            /* eslint-disable no-undef */
            WWOpenData.bind(this.$el)
        },
        mounted() {
            this.$nextTick(()=>{
                /* eslint-disable no-undef */
                WWOpenData.bind(this.$el)
            })
        }
    };
</script>

<style scoped>

</style>