//通知/更新等后端推送 操作 通知内部组件

const state = () => ({
    // 微信自动更新通知时间戳
    wxAutoUpdateTimeStamp: "",
})

const getters = {
    getWxAutoUpdateTimeStamp: (state) => {
        return state.wxAutoUpdateTimeStamp
    },
}

const actions = {
    // 微信自动更新通知时间戳 
    updateWxAutoUpdateTimeStamp({ commit }, timeStamp) {
        console.log('微信自动更新通知时间戳: ',timeStamp);
        commit('setWxAutoUpdateTimeStamp', timeStamp);
    },
}

const mutations = {
    setWxAutoUpdateTimeStamp(state, timeStamp) {
        state.wxAutoUpdateTimeStamp = timeStamp;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}