<template>
    <div @click="searchBotVisible" class="search-box">
        <span class="aphws-b-text" v-show="searchStatus == 'show'">
            <span class="aphws-b-search">
                <i class="iconfont guoran-tongyichicun-18-30-sousuo"></i>
            </span>
            搜索一切你所需要的
        </span>
        <section class="aphws-b-input" v-show="searchStatus == 'search'">
            <i class="iconfont guoran-tongyichicun-18-30-sousuo"></i>
            <el-input
                v-model="searchText"
                placeholder="搜索一切你所需要的"
                size="small"
                ref="searchIpt"
                @blur="searchBlur"
                @input="searchInput"
                @keyup.enter.native="searchEnter"
            ></el-input>
        </section>
        <div class="history-content" v-if="historyContentVisable && historyList.length != 0">
            <div v-if="searchText == ''" @click="clearHistory" class="remove-hsl">
                <i class="el-icon-delete"></i>
            </div>
            <div  class="history-list" v-if="searchText == ''">
                <p>历史搜索</p>
                <div class="hl-cont">
                    <section class="hl-view"  v-for="h in historyList" :key="h.id" @click="handleHistory(h)">{{ h.content }}</section>
                </div>
                <!-- <div class="line"></div>
                <p style="margin:0">大家都再搜</p>
                <div class="hl-cont">
                    <section class="hl-view" v-for="h in historyList" :key="h.id">{{ h.content }}</section>
                    <section class="hl-view" v-for="h in historyList" :key="h.id">{{ h.content }}</section>
                </div> -->
            </div>
            <div class="history-ul" v-else>
                <div class="history-li" v-for="(h, hIndex) in historyList" @click="handleHistory(h)" :key="h.id">
                    <span class="history-li-left">
                        <i class="iconfont guoran-lishibanben"></i>
                        {{h.content}}
                    </span>
                    <span class="history-li-right" >
                        <i class="el-icon-delete" @click.stop="removeHistory(hIndex)"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { requestUrl } from '../http/requestUrl'
export default {
    data () {
        return {
            searchStatus: "show",
            searchText: "",
            historyContentVisable: false,
            historyList: [],
        }
    },
    methods: {
        searchBlur () {
            if(this.searchText == '') {
                this.searchStatus = "show"
            }
            // this.historyContentVisable = false
            // setTimeout(() =>{
            //     this.historyContentVisable = false
            // }, 200)
        },
        searchInput () {
            this.getHistoryList()
        },
        searchEnter () {
            this.$emit("sendMessage", this.searchText, 'search')
            this.historyContentVisable = false
            this.$refs.searchIpt.blur()
        },

        searchBotVisible() {
            this.searchStatus = "search"
            this.historyContentVisable = true
            this.$nextTick(() =>{
                this.$refs.searchIpt && this.$refs.searchIpt.focus();
                this.getHistoryList()
            })
        },
        getHistoryList() {
            let memberInDepartDto = localStorage.getItem('memberInDepartDto') ? JSON.parse(localStorage.getItem('memberInDepartDto')) :null;
            let bindCode = sessionStorage.getItem('_bindCode');
            let userId = memberInDepartDto && memberInDepartDto.thirdUserId ? memberInDepartDto.thirdUserId : localStorage.getItem("_uid")
            this.$http(requestUrl.search.getHistoryList + 
                '?bindCode=' + bindCode + '&userId=' + userId + '&limit=10' + '&keyword=' + this.searchText).then(res =>{
                    if(res.data.code == '0') {
                        console.log(res.data.data, 'res.data.data');
                        this.historyList = res.data.data
                    }
            })
        },
        clearHistory() {
            let memberInDepartDto = localStorage.getItem('memberInDepartDto') ? JSON.parse(localStorage.getItem('memberInDepartDto')) : null;
            let bindCode = sessionStorage.getItem('_bindCode');
            let userId = memberInDepartDto && memberInDepartDto.thirdUserId ? memberInDepartDto.thirdUserId : localStorage.getItem("_uid")
            this.$http.delete(requestUrl.search.clearHistory + 
                '?bindCode=' + bindCode + '&userId=' + userId ).then(res =>{
                    if(res.data.code == '0') {
                        this.historyList = []
                    }
            })
        },
        handleHistory(h) {
            this.searchText = h.content
            this.$emit("sendMessage", h.content, 'search')
        },
        removeHistory(hIndex) {
            this.$http.delete(requestUrl.search.removeHistory + this.historyList[hIndex].id ).then(res =>{
                if(res.data.code == '0') {
                    this.historyList.splice(hIndex, 1)
                }
            })
        }
    },
    mounted() {
        document.addEventListener("click", (e) =>{
            let searchBox = document.getElementsByClassName('search-box')
            if(searchBox && searchBox[0] && !searchBox[0].contains(e.target)) {
                this.historyContentVisable = false
            }
        })
    }
}
</script>
<style lang="less" scoped>
.search-box {
    //flex: 1;
    // width: 482px;
    //width: 100%;
    // max-width: 482px;
    // min-width: 160px;
    flex: none;
    width: 500px!important;
    height: 35px;
    background: #eaefff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    color: #95b0ff;
    cursor: pointer;
    position: relative;
    align-items: center;
    // &.min{
    //     width: 350px;
    // }
    // &.max{
    //     width: 482px;
    // }

    .aphws-b-search {
        // flex: none;
        width: 40px;
        text-align: left;
        // margin-left: 12px;

        .iconfont {
            font-size: 12px;
        }
    }

    .aphws-b-text {
        text-align: center;
        flex: auto;
    }
    .aphws-b-input {
        display: flex;
        align-items: center;
        width: 100%;
        .iconfont {
            font-size: 12px;
            margin: 0 10px;
        }
        /deep/.el-input__inner {
            padding: 0;
            background-color: transparent;
            border: none;
        }

        /deep/.el-input {
            input::placeholder {
                color: #95b0ff;
            }
        }
    }
    .history-content {
        position: absolute;
        top: 41px;
        width: 100%;
        max-height: 300px;
        left: 0;
        background: #ffffff;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.28);
        border-radius: 15px;
        box-sizing: border-box;
        padding: 15px 10px 10px 10px;
        cursor: default;
        overflow: hidden;
        overflow-y: scroll;
        z-index: 9999;
        .remove-hsl {
            width: 32px;
            height: 32px;
            line-height: 32px;
            color: #366aff;
            background: #ecf0fb;
            border-radius: 50%;
            font-size: 18px;
            position: absolute;
            right: 10px;
            cursor: pointer;
        }
        .history-list {
            width: 100%;
            height: auto;
            text-align: left;
            p {
                color: #a9b3c6;
                margin-bottom: 8px;
                font-size: 14px;
            }
            .hl-cont {
                width: 100%;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                padding-right: 10px;
                .hl-view {
                    height: 18px;
                    padding: 3px 5px;
                    line-height: 18px;
                    background: #f0f2f8;
                    max-width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-radius: 5px;
                    font-size: 12px;
                    margin: 5px;
                    color: #404040;
                    flex: none;
                    cursor: pointer;
                }
            }
            .line {
                width: 100%;
                height: 1px;
                margin: 15px 0;
                background: #f0f2f8;
            }
        }
        .history-ul {
            width: 100%;
            .history-li {
                width: 100%;
                height: 28px;
                line-height: 28px;
                display: flex;
                align-items: center;
                color: #404040;
                .history-li-left {
                    width: calc(100% - 28px);
                    text-align: left;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    i {
                        color: #d8ddeb;
                        font-size: 14px;
                        margin: 0 5px;
                    }
                }
                .history-li-right {
                    width: 28px;
                    display: none;
                    cursor: pointer;
                }
                .history-li-right:hover {
                    color: #366aff;
                }
            }
            .history-li:hover {
                background: #ecf0fb;
                .history-li-right {
                    width: 28px;
                    display: block;
                }
            }
        }
    }
}
</style>