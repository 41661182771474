<template>
    <div class="welcome">
        <img class="welcome_logo" v-show="page == 1" src="../assets/images/welcome_logo.png" alt="">
        <div class="welcome_content" v-show="page == 1">
            <div class="welcome_img" ></div>
            <b class="size_h1">亲爱的用户，欢迎您来体验</b>
            <b class="size_h1">AskBot知识助手的无限魅力！</b>
            <ul>
                <li>
                    <section class="dian"></section>
                    <section>
                        <span>多种格式文档：Word、PDF等，一键上传，轻松搞定</span>
                        <span class="line"></span>
                    </section>
                </li>
                <li>
                    <section class="dian"></section>
                    <section>
                        <span>微信知识一键转入AskBot，让知识整理更轻松</span>
                        <span class="line"></span>
                    </section>
                </li>
                <li>
                    <section class="dian"></section>
                    <section>
                        <span>AI智能生成知识，助力工作与学习效率提升</span>
                        <span class="line"></span>
                    </section>
                </li>
            </ul>
            <p>我们为您提供的一站式知识平台，致力于集中管理与便捷获取！</p>
            <p>AskBot智能学习您的知识库，让您随时随地提问，获取所需信息。</p>
            <p>共同开启知识探索之旅，让工作和学习变得更加愉悦，尽在AskBot知识助手！</p>
            <div class="next" @click="clickNext">
                继续
            </div>
        </div>
        <div class="welcome_content_2" v-show="page == 2">
            <div class="title">如何将微信知识转入AskBot知识助手</div>
            <div class="chat_qrCode">
                <img  :src="chatQrCodeImg" alt="">
                <div class="text1">
                    <span class="text_number">1</span>
                    <span>
                        微信扫码添加AskBot知识助手企业微信账号
                    </span>
                </div>
                <div class="text2">
                    <span class="text_number"> 2</span>
                    <span>
                        将微信中文件，文章，聊天记录转发至AskBot知识助手企业微信账号
                    </span>
                </div>
            </div>
            <div class="to_index"  @click="goToIndex">立即开启知识探索之旅</div>
        </div>
    </div>
</template>
<script>
export default  {
    data(){
        return {
            page:1,
        }
    },
    computed:{
        chatQrCodeImg() {
            return  process.env.VUE_APP_NODE_ENV == 'production' ? require('@/assets/images/chat_qrCode.png') : require('@/assets/images/chat_qrCode_test.png') 
        }
    },
    methods:{
        clickNext() {
            // alert('1111')
            this.page = 2
            this.$emit('next')

        },
        goToIndex() {
            this.$emit('closeWelcome')
        }
    }
}
</script>
<style scoped lang="less">
.welcome {
    width: 100%;
    height: 100%;
    position: relative;
    background: #FFFFFF;
    font-family: PingFang SC !important;
    // min-width: 1200px;

    .welcome_logo {
        position: absolute;
        right: 15px;
        top: 0;
    }

    .welcome_content {
        position: absolute;
        width: 100%;
        // min-width: 1200px;
        height: 100%;
        top: 0;
        // margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
        text-align: left;
        box-sizing: border-box;

        .welcome_img {
            width: 415px;
            height: 415px;
            position: absolute;
            top: 115px;
            right: 0;
            z-index: 1;
            background: url(../assets/images/welcome.png);
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
        }

        .size_h1 {
            font-size: 38px;
            font-weight: bolder;
            display: block;
            color: #000000;

        }

        ul {
            margin: 65px 0;

            li {
                display: flex;
                align-items: center;
                margin-top: 10px;
                font-size: 17px;
                // font-weight: 500;
                color: #000000;
                height: 30px;
                line-height: 30px;

                .dian {
                    width: 11px;
                    height: 11px;
                    margin-right: 13px;
                    background: #366aff;
                    border-radius: 50%;
                }

                >section {
                    position: relative;

                    span {
                        display: inline-block;
                        position: relative;
                        z-index: 99;
                    }
                }

                .line {
                    position: absolute;
                    bottom: 2px;
                    left: -3px;

                    width: calc(100% + 6px);
                    height: 10px;
                    border-radius: 4px;
                    background: #CAD6FF;
                    z-index: 1;
                }
            }

        }

        p {
            font-size: 17px;
            font-family: PingFang SC;
            color: #000000;
            margin: 8px 0;
            position: relative;
            z-index: 99;
        }
        .next {
            width: 229px;
            height: 50px;
            background: #EEF2FF;
            border-radius: 25px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #366AFF;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
            margin-top: 75px;
        }

    }
    .welcome_content_2 {
        position: absolute;
        width: 100%;
        // min-width: 1200px;
        height: 100%;
        // max-height: 800px;
        top: 0;
        // margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
        text-align: left;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .title {
            height: 35px;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #000000;
            // line-height: 66px;
            text-align: center;
            margin-bottom: 15px;
        }
        .chat_qrCode {
            width: 900px;
            height: 430px;
            display: block;
            position: relative;
            // object-fit: cover;
            // position: absolute;
            // left: 50%;
            // transform: translateX(-50%);
            // float: left;
            margin: 20px auto 0;
            img {
                width: 100%;
                height: 100%;
            }
            .text1 {
                position: absolute;
                max-width: 390px;
                bottom: 65px;
                left: 64px;
                display: flex;
                align-items: flex-start;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 26px;
            }
            .text2 {
                position: absolute;
                max-width: 358px;
                bottom: 40px;
                right: 55px;
                display: flex;
                align-items: flex-start;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 26px;
            }
            .text_number {
                width: 25px;
                height: 25px;
                background: #366AFF;
                border-radius: 50%;
                font-size: 18px;
                color: #FFFFFF;
                line-height: 25px;
                text-align: center;
                display: block;
                margin-right: 5px;
                flex: none;
            }

        }
        .to_index {
            width: 349px;
            height: 50px;
            background: #366AFF;
            border-radius: 25px;
            color: #FFFFFF;
            text-align: center;
            // position: absolute;
            // left: 50%;
            // transform: translateX(-50%);
            margin: 40px auto;
            line-height: 50px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

// @media screen and (min-width:1200px){
//     .welcome_content {
//         max-width: 1200px;
//     }
// }

// @media screen and (max-width:1200px)  and (min-width:1500px){
//     .welcome_content {
//         max-width: 1400px;
//     }
// }
// @media screen and (max-width:1500px)  and (min-width:1800px){
//     .welcome_content {
//         max-width: 1400px;
//     }
// }
// @media screen and (min-width:1800px) {
//     .welcome_content {
//         max-width: 1400px;
//     }
// }

// @media  screen and (max-width:580px) {
//     .welcome_content {
//         max-width: 1100px;
//     }
// }

// @media screen and (max-width:580px){
//     .welcome_content {
//         max-width: 1400px;
//     }
// }
</style>
