<template>
    <div class="ask-lw-left">
        <!-- 导航 -->
        <nav class="ask-lw-nav">
            <div v-for="(item, itemIndex) in navList" :key="itemIndex" :class="['aln-cell']">
                <div v-if="item.visible" @click="handleSelect(item)"
                    :class="['aln-cell-con', activeNavId === item.id ? 'aln-cell-con-active' : '']">
                    <div class="aln-cell-con-pre">
                        <span :class="['aln-cell-con-icon', item.icon]"></span>
                        <span class="aln-cell-con-name">{{ item.name }}</span>
                    </div>
                    <div v-if="item.id === 'mode'" class="aln-cell-con-after">
                        <span @click.stop="modeConfig"
                            :class="['aln-cell-con-config', activeModeNavId === 'modeConfig' ? 'aln-cell-con-config-active' : '']">
                            <i class="iconfont guoran-shezhi"></i>
                        </span>
                        <span class="aln-cell-con-ud">
                            <span  @click.stop="switchExpand(true)" v-show="!modeListVisible"
                                class="arsenal_icon arsenalangle-right-solid"></span>
                            <span
                                @click.stop="switchExpand(false)"
                                v-show="modeListVisible && modeListObj.navModeList.length > 0"
                                class="arsenal_icon arsenalangle-down-solid"></span>
                        </span>
                    </div>
                </div>
                <!-- 模板子导航 -->
                <div v-if="item.id === 'mode' && modeListObj.navModeList.length > 0 && modeListVisible"
                    class="aln-cell-children nav-template-list">
                    <div @click="searchTemplate(cell)" v-for="(cell, cellIndex) in modeListObj.navModeList" :key="cellIndex"
                        class="aln-cell-children-item"
                        :style="{ backgroundColor: searchDocModeIdBgActive === cell.id ? '#EAEFFF' : '#F9FAFD' }">
                        <span :style="{ color: cell.color }" :class="['acci-icon', cell.icon]"></span>
                        <span class="acci-name">{{ cell.name }}</span>
                    </div>
                    <el-popover visible-arrow="true" popper-class="show-more-mode-content" placement="right" width="180"
                        trigger="hover">
                        <div class="show-more-mode">
                            <div @click="searchTemplate(cell)" v-for="(cell, cellIndex) in modeListObj.moreModeList"
                                :key="cellIndex" class="aln-cell-children-item"
                                :style="{ backgroundColor: searchDocModeIdBgActive === cell.id ? '#EAEFFF' : '#FFFFFF' }">
                                <span :style="{ color: cell.color }" :class="['acci-icon', cell.icon]"></span>
                                <span class="acci-name">{{ cell.name }}</span>
                            </div>
                        </div>
                        <div v-show="modeListObj.moreModeList.length > 0" style="padding-right: 12px;" id="more-mode"
                            slot="reference" class="aln-cell-children-item">
                            <span style="color: #366AFF"
                                class="acci-icon iconfont guoran-tongyichicun-gengduoguanli"></span>
                            <span class="acci-name">更多</span>
                            <span class="acci-name-more-icon">
                                <i class="iconfont guoran-right"></i>
                            </span>
                        </div>
                    </el-popover>
                </div>
                <!-- 模板子导航 -->
            </div>
            <el-button @click="showInviteDialog = true">邀请同事（导航改版后让如通讯录下）</el-button>
        </nav>
        <!-- 导航 -->
        <!-- 用户套餐信息 -->
        <div class="ask-lw-combination">
            <div class="alc-item">
                <div class="alc-item-title">
                    <span class="alc-item-title-pre">
                        <span class="aitp-name">智能问答与写作</span>
                        <el-tooltip class="item" effect="dark" content="使用AI能力生成文案以及答案" placement="top-end">
                            <span class="iconfont guoran-tongyichicun-yiguoqi"></span>
                        </el-tooltip>
                    </span>
                    <!-- <span  @click="upgradePackage('writing')" class="alc-item-title-after">升级</span> -->
                    <span @click="openPayVersion" class="alc-item-title-after" v-if="currentVersion.vipType != 3">升级</span>
                </div>
                <div class="alc-item-value">
                    <span class="alc-item-value-pre">
                        <span :style="{width: combinationInfo.flowPercentage + '%'}" class="alc-item-value-pre-innner"></span>
                    </span>
                    <span class="alc-item-value-after">
                        <span class="aiva-user">{{ combinationInfo.flowUsedText }}/</span>
                        <span class="aiva-all">{{ combinationInfo.flowTotalText }}次</span>
                        <!-- 0：免费版，1：超级会员，2：高级会员 3 企业版 -->
                        <span v-if="currentVersion.vipType === 3">
                            <span v-if="combinationInfo.companyFlowMonthRefresh">/月</span>
                        </span>
                       <span v-else-if="(currentVersion.vipType === 1 || currentVersion.vipType === 2) && !currentVersion.expire">/月</span>
                    </span>
                </div>
                <div class="alc-item-red-tips" v-show="combinationInfo.flowPercentage >= 80 && combinationInfo.flowPercentage < 100">次数即将用尽</div>
                <div class="alc-item-red-tips" v-show="combinationInfo.flowPercentage >= 100">次数已用尽</div>
            </div>
            <div class="alc-item" v-show="isShowTimesPacket">
                <div class="alc-item-title">
                    <span class="alc-item-title-pre">
                        <span class="aitp-name">次数包</span>
                        <el-tooltip class="item" effect="dark" content="每次购买的次数包有限期为365天" placement="top-end">
                            <span class="iconfont guoran-tongyichicun-yiguoqi"></span>
                        </el-tooltip>
                    </span>

                </div>
                <div class="alc-item-value">
                    <div class="alc-item-value-surplus-times">
                        <span class="surplus-times">剩余<span class="times">{{combinationInfo.flowPackageTotal}}次</span></span>
                        <span @click="openPayVersion" class="alc-item-title-after" v-if="currentVersion.vipType != 3">购买次数</span>
                    </div>
                </div>
                <div class="alc-item-red-tips" v-if="combinationInfo.flowPackageTotal > 0 && combinationInfo.earliestExpireFlowCount > 0 && combinationInfo.withinAmonth">{{combinationInfo.earliestExpireFlowCount}}次将于{{combinationInfo.earliestExpireTime }}到期</div>
            </div>
            <div class="alc-item">
                <div class="alc-item-title">
                    <span class="alc-item-title-pre">
                        <span class="aitp-name">容量</span>
                    </span>
                    <!-- <span @click="upgradePackage('volume')" class="alc-item-title-after">扩容</span> -->
                    <span @click="openPayVersion" class="alc-item-title-after" v-if="currentVersion.vipType != 3">升级</span>
                </div>
                <div class="alc-item-value">
                    <span class="alc-item-value-pre">
                        <span :style="{width: combinationInfo.capacityPercentage + '%'}" class="alc-item-value-pre-innner"></span>
                    </span>
                    <span class="alc-item-value-after">
                        <span class="aiva-user">{{handlerUnit(combinationInfo.capacityUsed + combinationInfo.companyCapacityUsed)}}/</span>
                        <span class="aiva-all" >{{handlerUnit(combinationInfo.capacityTotal)}}</span>
                    </span>
                </div>
                <div class="alc-item-red-tips" v-show="combinationInfo.capacityPercentage >= 90 && combinationInfo.capacityPercentage < 100">容量即将用尽</div>
                <div class="alc-item-red-tips" v-show=" combinationInfo.capacityPercentage >= 100">容量已用尽</div>
            </div>
            <div class="alc-item company-tips" v-if="currentVersion.vipType == 3" >
                次数和容量不够用？<span @click="goBuy">点我</span>
            </div>
        </div>
        <inviteDialog :showInviteDialog="showInviteDialog"  @close="showInviteDialog = false" />
        <beyondLimit
			:showMorePopups.sync="showMorePopups"
            :showApplyInfoPopups.sync="showApplyInfoPopups"
			:exceedObj="exceedObj"
			:currentVersion="currentVersion">
		</beyondLimit>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import beyondLimit from './beyondLimit.vue'
import { requestUrl } from "./../../http/requestUrl";
import { redirectUri } from "@/http/redirectUri";
import inviteDialog from '../../components/inviteUsers/inviteDialog.vue';
export default {
    name: "MainNav",
    components:{beyondLimit,inviteDialog},
    props: {
        // 套餐信息 智能写作/容量
        combinationInfo: {
            type: Object,
            default: () => {
                return {
                    capacityUsed: 0,
                    capacityTotal: 1,
                    capacitySurplus: 1,
                    flowUsed: 0,
                    flowTotal: 100,
                    flowSurplus: 100
                }
            }
        },
        // 模板列表
        modeListObj: {
            type: Object,
            default: () => {
                return {
                    navModeList: [],
                    moreModeList: []
                }
            }
        }
    },
    data() {
        return {
            classList: ['more-mode'],
            modeListChildren: [],
            activeNavId: "index", // 当前导航id
            activeModeNavId: "", // 模版配置
            modeListVisible: false, // 模版list下拉是否可见
            navList: [
                { name: "首页", id: 'index', icon: "iconfont guoran-tongyichicun-shouye", children: [], visible: true, route: '' },
                { name: "智能助手", id: 'bot', icon: "iconfont guoran-tongyichicun-jiqirentouxiang_didaohang", children: [], visible: true, route: '' },
                { name: "待办", id: 'toDo', icon: "iconfont guoran-daiban", children: [], visible: false, route: '' },
                {
                    name: "模板",
                    id: 'mode',
                    icon: "iconfont guoran-tongyichicun-14-05-mobanshuxing",
                    visible: true,
                    route: ''
                },
                { name: "回收站", id: "recycleBin", icon: "iconfont guoran-huishouzhan", children: [], visible: true, route: '' }
            ],
            moreModeList: [],
            navModeList: [],
            searchDocModeIdBgActive: "",
            moreModVisible: false,
            isShowTimesPacket:true,
            isExpand:false,
            screenWidthTimer: false, // 监听页面宽度变化节流 timer
            showMorePopups:false,
            showApplyInfoPopups:false,
            knowledgeUserName:"",
            showInviteDialog: false,
        }
    },
    computed: {
        ...mapGetters('user', {
            // 左侧导航当前 通过模板 筛选知识 高亮
            searchDocModeIdNav: 'activeSearchDocModeId',
        }),
        ...mapGetters('user', {
            currentVersion: 'getCurrentVersion'
        }),
        // ...mapGetters('apphandle', { // 是否是切换企业
        //     isSwitchCompany: 'getIsSwitchCompany'
        // }),
    },
    methods: {
        switchExpand(flag){
            // this.isExpand = flag;
            this.modeListVisible = flag;
            if(this.modeListVisible){
                this.handlerNavHeight()
            }
        },
        openPayVersion(){
            this.$router.push({name:"payVersionView"})
        },
        clickOutSideMoreMode() {
            this.moreModVisible = false;
        },
        // 顶部触发添加知识 修改状态管理 apphandle 模块
        ...mapActions('apphandle', [
            'updateIsTopAddDoc'
        ]),
        ...mapActions('apphandle', [
            "updateIsTopSearch"
        ]),
        ...mapActions('user', [
            "updateSearchDocModeId"
        ]),
        ...mapActions('user', [
            "updateLastClickLNavTamp"
        ]),
        ...mapActions('user', [
            'updateCombinationInfo'
        ]),
        ...mapActions('user', [
            'updateCurrentVersionInfo'
        ]),
        // 更新
        // ...mapActions('apphandle', [
        //     'updateIsSwitchCompany'
        // ]),
        /**
         * 点击导航，调用父组件相应方法
         * @method handleSelect
         * @param {object} item 导航每项数据
         * @return 点击导航，调用父组件相应方法
         */
        handleSelect(item) {
            // this.$emit("closeTip");
            // this.updateSearchDocModeId("");
            if (item.id === "mode") {
                this.modeListVisible = !this.modeListVisible;
                if(this.modeListVisible){
                    this.handlerNavHeight()
                }
                // this.activeNavId = "modeConfig";
                // this.activeModeNavId = "modeConfig";
                // this.updateIsTopAddDoc({
                //     status: false,
                //     fromRouteName: ""
                // });
                // this.$router.push({ name: "TemplateView", query: "" })
            } else {
                if (item.id !== 'managementBackend'){
                  this.activeNavId = item.id;
                }
                this.activeModeNavId = "";
                switch (item.id) {
                    case "index":
                        this.updateIsTopAddDoc({
                            status: false,
                            fromRouteName: ""
                        });
                        this.searchDocModeIdBgActive = "";
                        this.$router.push({ name: "IndexView", query: "" })
                        this.updateLastClickLNavTamp(new Date().getTime());
                        break;
                    case "bot":
                        // this.updateIsTopAddDoc({
                        //     status: false,
                        //     fromRouteName: ""
                        // });
                        // this.$emit("searchBotVisible", false);
                        this.$http.get(requestUrl.knowledge.getBotBindInfo + "?userId=" + localStorage.getItem("_uid"))
                        .then((res) => {
                            if (res.data.code === "0") {
                                sessionStorage.setItem('botBindInfo', JSON.stringify(res.data.data))
                                this.$router.push({ name:'searchKnowledge', params:{ indexToSearch: true } })
                            }
                        });
                        // this.$router.push({ name: "IntelligentAssistant", query: "" })
                        break;
                    case "recycleBin":
                        this.updateIsTopAddDoc({
                            status: false,
                            fromRouteName: ""
                        });
                        this.$router.push({ name: "recycleBinView", query: "" })
                        break;
                    case "enterpriseKnowledgeView":
                        this.updateIsTopAddDoc({
                            status: false,
                            fromRouteName: ""
                        });
                        this.$router.push({ name: "enterpriseKnowledgeView", query: "" })
                        break;
                    case "workOrderView":
                        this.updateIsTopAddDoc({
                          status: false,
                          fromRouteName: ""
                        });
                        this.$router.push({ name: "workOrderView", query: "" })
                        break;
                    case "managementBackend":
                        this.goManage();
                        break;
                    case "toDo":
                    this.$router.push({ name: "toDoList", query: "" })
                        break;
                        default:
                            break;
                }
            }
        },
        /**
         * 点击导航中 模板/模板配置 按钮，路由跳转至 模板配置功能
         * @method modeConfig
         * @param {}
         * @return {}
         */
        modeConfig() {
            this.activeNavId = "modeConfig";
            this.activeModeNavId = "modeConfig";
            this.$router.push({ name: "TemplateView", query: "" });
        },
        /**
         * 点击导航底部 升级/扩容 按钮 调用父组件相关功能
         * @method upgradePackage
         * @param {string} type  writing 智能写作升级 volume 容量扩容
         * @return {}
         */
        upgradePackage(type) {
            this.$emit('upgradePackageVisible', type);
        },
        /**
         * 点击导航 设置当前筛选的模板id 根据模板筛选知识列表
         * @method searchTemplate
         * @param {object} cell 当前点击模板数据
         * @return {}
         */
        searchTemplate(cell) {
            this.$emit("closeTip");
            this.moreModVisible = false;
            this.searchDocModeIdBgActive = cell.id;
            this.activeModeNavId = cell.id;
            this.updateSearchDocModeId(cell.id);
            this.activeNavId = "";
            this.$nextTick(() => {
                console.log(this.$route.path);
                // if (this.$route.path !== "/index") {
                //     this.$router.push({
                //         name: "IndexView", query: {
                //             isNavSearchDoc: 1,
                //             templateId: cell.id
                //         }
                //     });
                // }
                this.$router.push({
                    name: "IndexView", query: {
                        isNavSearchDoc: 1,
                        templateId: cell.id
                    }
                });
            })
        },
        // 获取容量和使用次数信息
        async getVerInfo() {
            console.log(this.currentVersion);
            let param = {
                mainId: localStorage.getItem("_mainId"),
                userId: localStorage.getItem("_uid"),
                vipType:this.currentVersion.vipType,
                expire:this.currentVersion.expire,
                userSource:localStorage.getItem('accountSource')
            }
            await this.$http
                .post(requestUrl.upload.getCapacity)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.updateCombinationInfo(res.data.data);

                    } else {
                        this.messageFn('error', res.data.message);
                    }
                });
             this.$http
                .post(requestUrl.upload.getCount, param)
                .then((res1) => {
                    this.updateCombinationInfo(res1.data);
                    console.log(this.combinationInfo,'combinationInfo');
                });
        },
        //  查询用户会员信息、流量包、空间容
        selectUserBuyAbility(){
            this.$http.get(requestUrl.pay.selectUserBuyAbility).then((res) => {
                if (res.data.code == 0) {
                    console.log('main');
                    this.updateCurrentVersionInfo(res.data.data);
                    this.getVerInfo();
                }
            })
        },
         screenWidth() {
            if (!this.screenWidthTimer) {
                this.screenWidthTimer = true;
                setTimeout(() => {
                    this.screenWidthTimer = false;
                    this.handlerNavHeight()
                }, 300)
            }
        },
        handlerNavHeight(){
            this.$nextTick(() => {
                let bottomVersionHeight =  document.querySelector('.ask-lw-combination') && document.querySelector('.ask-lw-combination').clientHeight;
                let totalHeight = document.querySelector('.ask-lw-nav') && document.querySelector('.ask-lw-nav').clientHeight - bottomVersionHeight - 20;
                let menuDomList = Array.from(document.querySelectorAll('.aln-cell'));
                let menuHieight = menuDomList.length * 38;
                let superHeight = totalHeight - menuHieight;
                let dom = document.querySelector('.nav-template-list')
                if(dom){
                    document.querySelector('.nav-template-list').style.maxHeight = superHeight + 'px';
                }
            })
        },
        handlerUnit(value){
           return value/1024/1024/1024>=1 ? (value/1024/1024/1024).toFixed(1) + 'GB' : (value/1024/1024).toFixed(1) + 'MB'
        },
        goBuy(){
            this.showMorePopups = true;
        },
        // 更新菜单列表
        updateNavList(){
            let accountSource = localStorage.getItem('accountSource')
            console.log(accountSource,'accountSource-获取');
            this.knowledgeUserName = localStorage.getItem('knowledgeUserName');
            let haveWorkOrderPermission = localStorage.getItem('haveWorkOrderPermission');
            console.log('haveWorkOrderPermission',haveWorkOrderPermission)
            // 9 个人 ; 其他 : 企业
            // if (userIds.indexOf(userId) != -1 || process.env.VUE_APP_NODE_ENV == 'development'){
            if (accountSource == 11 || accountSource == 12){
                this.navList = [
                    { name: "我的知识", id: 'index', icon: "iconfont guoran-tongyichicun-shouye", children: [], visible: true, route: '' },
                    { name: "待办", id: 'toDo', icon: "iconfont guoran-daiban", children: [], visible: true, route: '' },
                    { name: "智能助手", id: 'bot', icon: "iconfont guoran-tongyichicun-jiqirentouxiang_didaohang", children: [], visible: true, route: '' },
                    { name: "企业知识", id: 'enterpriseKnowledgeView', icon: "iconfont guoran-zhishi", children: [], visible: true, route: '' },
                    {
                        name: "模板",
                        id: 'mode',
                        icon: "iconfont guoran-tongyichicun-14-05-mobanshuxing",
                        visible: true,
                        route: ''
                    },
                    { name: "回收站", id: "recycleBin", icon: "iconfont guoran-huishouzhan", children: [], visible: true, route: '' },
                ]
                if (haveWorkOrderPermission){
                  let orderObj = { name: "工单", id: 'workOrderView', icon: "iconfont guoran-AIgongdan", children: [], visible: true, route: '' }
                  this.navList.splice(4,0,orderObj)
                }
                if (this.knowledgeUserName && this.knowledgeUserName !== 'null'){
                    let obj = { name:"管理后台",id:"managementBackend",icon:"iconfont guoran-houtaiguanli",children: [],visible: true,route: '' };
                    this.navList.push(obj)
                }
            } else {
                this.navList = [
                    { name: "首页", id: 'index', icon: "iconfont guoran-tongyichicun-shouye", children: [], visible: true, route: '' },
                    { name: "待办", id: 'toDo', icon: "iconfont guoran-daiban", children: [], visible: true, route: '' },
                    { name: "智能助手", id: 'bot', icon: "iconfont guoran-tongyichicun-jiqirentouxiang_didaohang", children: [], visible: true, route: '' },
                    {
                        name: "模板",
                        id: 'mode',
                        icon: "iconfont guoran-tongyichicun-14-05-mobanshuxing",
                        visible: true,
                        route: ''
                    },
                    { name: "回收站", id: "recycleBin", icon: "iconfont guoran-huishouzhan", children: [], visible: true, route: '' },
                ]
            }
        },
        //跳转至管理后台
        goManage(){
        // this.$http.post(requestUrl.company.getCompanyToken+'?tel=' + localStorage.getItem('phone') + '&mainId=' + localStorage.getItem('_mainId')+ '&username='+localStorage.getItem('realName'))
          this.$http.post(`${requestUrl.company.getCompanyToken}`,{
            username:localStorage.getItem('realName'),
            tel:localStorage.getItem('phone'),
            mainId:localStorage.getItem('_mainId'),
            profilePhoto: localStorage.getItem('profilePhoto') == 'null' ? '' : localStorage.getItem('profilePhoto'),
          }).then((res) => {
            console.log(res,'token');
            this.popoverVisible = false;
            if(res.data){
              let redirectUrl = '';
              let tempwindow = window.open("_blank");
              // haveManagerPermission用户是否有管理后台操作权限，若该值为true,点击管理后台跳转管理平台，
              // haveKnowledgePermission为null且haveKnowledgePermission=true，跳转知识管理后台
              let haveManagerPermission = localStorage.getItem('haveManagerPermission');
              let haveKnowledgePermission = localStorage.getItem('haveKnowledgePermission');
              if(haveManagerPermission){
                redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].callback : redirectUri['development'].callback;
                if(window.location.hostname.includes('ebot.isheely.com')) {
                  tempwindow.location = 'https://portal.ebot.isheely.com' + '#/auth' + '?token=' + res.data + '&isAskLightning=1';
                } else {
                  tempwindow.location = redirectUrl + '#/auth' + '?token=' + res.data + '&isAskLightning=1';
                }
              } else if(haveManagerPermission == 'null' && haveKnowledgePermission == 'true') {
                redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].wisdom : redirectUri['development'].wisdom;
                if(window.location.hostname.includes('ebot.isheely.com')) {
                  tempwindow.location = 'https://wisdom.ebot.isheely.com/lightning-web' + '#/auth' + '?token=' + res.data + '&isAskLightning=1';
                } else {
                  tempwindow.location = redirectUrl + '/#/?token=' + res.data;
                }
              }
            } else {
              this.messageFn('error', res.message);
            }
          });
        // let redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].callback : redirectUri['development'].callback;
        // console.log(redirectUrl,'redirectUrl');
        // let tempwindow = window.open("_blank");
        // tempwindow.location = redirectUrl;

      },
    },
    watch: {
        searchDocModeIdNav(newVal) {
            console.log(265, newVal);
            if (this.$route.path === "/index" && newVal === "") {
                this.activeNavId = "index";
                this.activeModeNavId = "";
            }
            this.searchDocModeIdBgActive = newVal;
        },
        // isSwitchCompany(n,o){
        //     console.log(n,o,'isSwitchCompany-nav');
        //     if(n){
        //         this.updateNavList();
        //         let loading = this.$loading({
        //             lock: true,
        //             text: '切换中，请稍等...',
        //             spinner: 'el-icon-loading',
        //             background: 'hsla(0,0%,100%,.7)'
        //         });
        //         setTimeout(() => {
        //             loading.close();
        //             this.updateIsSwitchCompany(false); // 更新store
        //         }, 1500);
        //     }
        // },
    },
    mounted() {

        // let userId = localStorage.getItem("_uid");
        // let userIds = ['91408','91496','91403','91397','91871','92002','91974','91955','91963','91624','91405'];
        //
        this.knowledgeUserName = localStorage.getItem('knowledgeUserName');
        this.updateNavList();
        if (this.$route.path.split("/")[1] === "template") {
            this.activeNavId = "modeConfig";
            this.activeModeNavId = "modeConfig";
        } else {
            if(this.$route.path.split("/")[1] !== 'auth'){
                this.activeNavId = this.$route.path.split("/")[1];
            }
            this.activeModeNavId = "";
        }
        window.addEventListener("load", () => {
            this.$parent.getInviteInfo();
            // 监听页面强制刷新
            if (this.$route.path !== '/login' && this.$route.path !== '/auth' && this.$route.path !== '/index' && this.$route.path !== '/payVersion') {
                this.selectUserBuyAbility();
            }
        });
        this.handlerNavHeight();
        window.addEventListener('resize', () => {
            this.screenWidth();
        })
    },
    created() {

    },
    beforeDestroy(){
        window.removeEventListener('resize', () => {
            this.screenWidth();
        })
    },
    destroyed() {

    },
}
</script>
<style lang="less">
@import "./../../assets/less/common.less";
.show-more-mode-content {
    max-height: calc(100vh - 200px);
    padding: 0px !important;
    overflow-y: auto;
    left: 210px !important;

    .show-more-mode {
        margin: 12px 0;

        .aln-cell-children-item {
            padding: 0 12px;
            height: 36px;
            line-height: 36px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;

            .acci-icon {
                display: inline-block;
                margin-right: 6px;
                font-size: 14px;
                font-weight: 600;
            }

            .guoran-tongyichicun-14-05-mobanshuxing {
                font-size: 12px !important;
            }

            .acci-name {
                font-size: 12px;
                color: #404040;
                user-select: none;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .aln-cell-children-item:hover {
            .acci-name {
                color: @system_ask_color;
            }
        }
    }
}
</style>
<style lang="less" scoped>
@import "./../../assets/less/common.less";
.ask-lw-left {
    flex: none;
    width: 220px;
    // padding: 0px 0px 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ask-lw-nav {
        flex: auto;

        .aln-cell {

            text-align: left;

            .aln-cell-con {
                border-radius: 5px;
                margin: 0 10px;
                padding: 0 8px;
                height: 38px;
                line-height: 38px;
                color: #404040;
                font-size: 14px;
                display: flex;
                justify-content: space-between;

                .aln-cell-con-pre {
                    flex: auto;
                    font-weight: 500;

                    .aln-cell-con-icon {
                        display: inline-block;
                        margin-right: 6px;
                        font-size: 14px;
                    }
                }

                .aln-cell-con-after {
                    width: 50px;
                    flex: none;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: #000000;

                    .aln-cell-con-config {
                        line-height: 22px;
                        height: 22px;
                        width: 22px;
                        margin-right: 6px;
                        text-align: center;

                        i {
                            font-size: 18px;
                        }
                    }

                    .aln-cell-con-config-active {
                        background-color: #EAEFFF;
                        color: @system_ask_color;
                    }

                    .aln-cell-con-ud {
                        span {
                            font-size: 12px;
                        }
                    }
                }
            }

            .aln-cell-con:hover {
                cursor: pointer;
                color: @system_ask_color;
                background-color: rgba(234, 239, 255, .4);
            }

            .aln-cell-con-active {
                background: #EAEFFF;
                color: @system_ask_color;
            }

            .aln-cell-children {
                padding: 2px 0px;
                margin: 4px 0;
                // max-height: 350px;
                overflow-x: hidden;
                overflow-y: auto;

                .aln-cell-children-item {
                    padding: 0 36px;
                    background-color: #F9FAFD;
                    height: 36px;
                    line-height: 36px;
                    display: flex;
                    justify-content: flex-start;
                    cursor: pointer;

                    .acci-icon {
                        font-weight: 600;
                        display: inline-block;
                        margin-right: 6px;
                        font-size: 14px;
                    }

                    .acci-name-more-icon {
                        width: 120px;
                        text-align: right;

                        i {
                            font-size: 12px;
                        }
                    }

                    .guoran-tongyichicun-14-05-mobanshuxing {
                        font-size: 12px !important;
                    }

                    .acci-name {
                        font-size: 12px;
                        color: #404040;
                        user-select: none;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .aln-cell-children-item:hover {
                    .acci-name {
                        color: @system_ask_color;
                    }

                    .acci-name-more-icon {
                        color: @system_ask_color;
                    }
                }
            }
        }
    }

    .ask-lw-combination {
        // height: 280px;
        width: 200px;
        position:fixed;
        left: 10px;
        bottom: 0;

        .alc-item {
            width: 100%;
            padding-bottom: 8px;
            margin-top: 8px;
            border-bottom: 1px dotted #E2E6EE;

            .alc-item-title {
                display: flex;
                justify-content: space-between;
                font-weight: 500;

                .alc-item-title-pre {
                    text-align: left;
                    flex: auto;
                    display: flex;
                    align-items: center;

                    .aitp-name {
                        margin-right: 3px;
                    }

                    .guoran-tongyichicun-yiguoqi {
                        font-size: 14px;
                        color: #B0C4FF;
                    }
                }

                .alc-item-title-after {
                    text-align: right;
                    cursor: pointer;
                    // width: 40px;
                    flex: none;
                    color: @system_ask_color;
                }
            }

            .alc-item-value {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .alc-item-value-surplus-times{
                    width: 100%;
                    color: #366AFF;
                    font-size: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .times{
                        font-size: 12px;
                    }
                    .alc-item-title-after{
                        font-weight: 500;
                        cursor: pointer;
                        font-size: 14px;
                    }
                    .surplus-times{
                        color:#999999;
                        .times{
                            color: #366AFF;
                        }
                    }
                }

                .alc-item-value-pre {
                    width: 90px;
                    max-width: 90px;
                    height: 3px;
                    background: #E8EEFF;
                    border-radius: 2px;

                    .alc-item-value-pre-innner {
                        max-width: 90px;
                        display: block;
                        height: 3px;
                        // width: 50px;
                        background: @system_ask_color;
                        border-radius: 2px;
                    }
                }

                .alc-item-value-after {
                    margin-top: 2px;
                    font-size: 12px;

                    .aiva-user {
                        color: #A9B3C6;
                    }

                    .aiva-all {
                        color: @system_ask_color;
                    }
                }

            }

            .alc-item-red-tips{
                font-size: 12px;
                color: #FE5965;
                text-align: left;
                margin-top: 2px;
            }
            &:last-child{
                padding-bottom: 26px;
                border: none;
            }
        }
        .company-tips{
            font-size: 12px;
            color: #999999;
            text-align: left;
            span{
                cursor: pointer;
                color: #366AFF;
            }
        }
    }
}
</style>
