// /***
//  * 自定义弹框事件 (处理多次弹出事件)
//  *  */
import Vue from 'vue'
class AlertFn {
    constructor(obj) {
        if (!obj) return;
        if (typeof obj == 'object') {
            this.alertdata(obj.type || 'info', obj.message)
        } else {
            this.alertdata('info', obj)
        }
    }

    error (str) {
        this.alertdata('error', str)
    }

    success (str) {
        this.alertdata('success', str)
    }

    warning (str) {
        this.alertdata('warning', str)
    }

    alertdata (type, str) {
        Vue.prototype.$message.closeAll();
        Vue.prototype.$message({
            type: type,
            message: str
        })
    }
}
new AlertFn()
function messageFn (type, message, duration = 2000) {
    this.$message.closeAll()
    this.$message({
        type: type,
        message: message,
        duration: duration
    })
}


const GetQueryString = () => {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    //截取#前的url
    let dz_url = window.location.href.split('#')[0];

    let query = dz_url.substr(dz_url.lastIndexOf("?") + 1);
    let r = query.match(reg); //search,查询？后面的参数，并匹配正则
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}

//生成从minNum到maxNum-1的随机数
function randomNum (minNum, maxNum) {
    switch (arguments.length) {
        case 1:
            return parseInt(Math.random() * minNum + 1, 10) - 1
        case 2:
            return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10) - 1
        default:
            return 0
    }
}

export default {
    messageFn,
    GetQueryString,
    randomNum
}
