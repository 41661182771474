<template>
    <div class="lw-use-guide">
        lw-use-guide
        <span @click="closeUseGuide" class="close-use-guide">收起</span>
    </div>
</template>
<script>
export default {
    name: "UseGuide",
    props: {
    
    },
    data() {
        return {
            iframeSrc: "",
        }
    },
    methods: {
        closeUseGuide(){
            this.$emit("changeGuideVisible", false);
        }
    },
    mounted() {

    }
}
</script>
<style lang="less" scoped>
@import "./../../assets/less/common.less";

.lw-use-guide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
</style>