import axios from 'axios';

// axios 0.22.0 版本后提供了 AbortController API 用来取消请求
// 注意：AbortController是fetch API提供的，不需要从axios引入
// isCancel用于判断请求是不是被AbortController取消的
const { isCancel } = axios

// 声明一个 Map 用于存储每一个请求的标识 和 取消函数

const pending = new Map()

// 需要进行重复请求拦截的接口列表
const cacheWhiteList = ["dialog-api/dialog/async/v2/"]

import { redirect2LoginPage } from "./redirectUri";
import { doCheckAndRestStorageInfo } from "./auth";

let instance = axios.create({
    timeout: 60000
});
instance.interceptors.request.use(
    config => {
        doCheckAndRestStorageInfo();
        if(!sessionStorage.getItem("_isClient")) {
            let token = localStorage.getItem('token');
            config.headers.Authorization = 'Bearer ' + token;
            config.headers.mainId = localStorage.getItem('_mainId') ? localStorage.getItem('_mainId') : sessionStorage.getItem('_mainId');
            config.headers.uid = localStorage.getItem('_uid');
            if (localStorage.getItem('_mainId') == null || localStorage.getItem('_mainId') == '') {
                // location.hash = "/auth";
            }
        }
       
        const { url } = config
        let reqKey = ""
        if(url.indexOf("dialog-api/dialog/async/v2/") !== -1) {
            reqKey = "dialog-api/dialog/async/v2/"
        }
        if (cacheWhiteList.indexOf(reqKey) !== -1) {
            removeCacheRequest(reqKey)
            config.cancelToken = new axios.CancelToken(cancel => {
                if (!pending.has(reqKey)) { // 若是 pending 中不存在当前请求，则添加进去
                    pending.set(reqKey, cancel)
                }
            })
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//axios response 拦截器
instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (isCancel(error)) {
            // 通过CancelToken取消的请求不做任何处理
            return Promise.reject({
                message: '重复请求，自动拦截并取消'
            })
        } else {
            if (error.response.status === 403 || error.response.code == '5000') {
                redirect2LoginPage();
                console.log(error.response.status);
            } else if (error.response.status === 510) {
                console.log("============== exe 510 action");
                // location.href = process.env.VUE_APP_EXPIRED_URL;
            } else {
                return Promise.reject(error)
            }
        } 
    }
);

const removeCacheRequest = (reqKey) => {
    if (pending.has(reqKey)) {
        for (const [reqKey, cancel] of pending) {
            cancel(reqKey)
        }
        pending.clear()
    }
}

export default instance;