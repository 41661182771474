<template>
    <div>
        <!-- 弹出对话框 -->
        <ask-dialog v-if="dialogVisible" @closeEvent="closeDialog" :popZindex="3333" :haveTips="true"
            :isNeedScroll="false" class="dialog-footer-can-use-popup" :popWidth="500">
            <div slot="popup-name">邀请同事加入企业</div>
            <div slot="popup-tip">成员访问链接可申请加入企业</div>
            <!-- 邀请链接区域 -->
            <div slot="popup-con" class="popup-con-box">
              <div class="link">
                我和
                <!-- {{memberInfo4Client.fullName}}” -->
                <open-data v-if="isWechat" :openid="memberInfo4Client.fullName" :type="'departmentName'"></open-data>
                <span v-else>{{memberInfo4Client.fullName}}</span>
                的小伙伴都在AskBot等你，用这个专属链接加入我们吧！
               {{inviteLink}}
              </div>
<!--                <el-input type="textarea" :rows="5" v-model="inviteLink" readonly class="invite-link-box"></el-input>-->
            </div>

            <!-- 复制按钮 -->
            <div slot="dialog-footer" class="dialog-footer">
                <el-button type="primary" @click="copyLink">复制链接发送同事</el-button>
            </div>
        </ask-dialog>
    </div>
</template>

<script>
import AskDialog from "../../views/testComponents/AskDialog.vue";
import OpenData from "@/components/openData";
export default {
    data() {
        return {
            dialogVisible: false,
            inviteLink: '',
          memberInfo4Client:{
            type:'',
            bindType:'',
            memberId:'',
            name:'',
            fullName:''
          },
          isWechat:false
        };
    },
    props: {
        showInviteDialog: {
            type: Boolean,
            default: false, // default 应该是一个返回默认值的函数
        },
    },
    components: { OpenData, AskDialog },
    watch: {
        showInviteDialog(newVal) {
            if (newVal) {
                console.log("111111",JSON.parse(localStorage.getItem('memberInDepartDto')))
                this.generateInviteLink(); // 当对话框打开时生成邀请码
            }
        },
    },
    methods: {
        openDialog() {
            this.dialogVisible = true;
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$emit('close'); // 通知父组件关闭对话框
        },
        generateInviteLink() {
            let corpId = localStorage.getItem('_corpId');
            // let mainId = localStorage.getItem('_mainId');
            // let uid = localStorage.getItem("_uid");
            let memberInDepartDto = JSON.parse(localStorage.getItem('memberInDepartDto'));
            let departmentId
            // 检查 departIdList 是否存在并且是数组
            if (memberInDepartDto && Array.isArray(memberInDepartDto.departIdList)) {
                departmentId = memberInDepartDto.departIdList[0];
                console.log("departId:", departmentId);
            } else {
                console.log("departIdList 不存在或不是数组");
            }
            let userId = localStorage.getItem("_uid");
            // 构建请求URL
            const inviteApiUrl = `/portal-api/invite/getInviteSet?corpId=${corpId}&departmentId=${departmentId ? departmentId : ''}&userId=${userId}`;

            // 使用 this.$http 发送请求，获取邀请码数据
            this.$http.get(inviteApiUrl)
                .then(response => {
                    if (response.data.code === "0") {
                        // 请求成功，处理返回的数据
                        const inviteData = response.data.data;
                        this.inviteLink = inviteData.inviteShortUrl;  // 从响应中获取邀请链接

                        // 打开对话框并显示生成的邀请链接
                        this.openDialog();
                    } else {
                        // 请求返回错误，提示用户
                        this.$message.error("获取邀请链接失败，请重试！");
                    }
                })
                .catch(error => {
                    // 网络请求失败，处理错误
                    console.error("请求邀请码失败:", error);
                    this.$message.error("请求邀请链接时出错，请检查网络或稍后再试！");
                });
        },
        copyLink() {
            // 复制链接到剪贴板
            const el = document.createElement("textarea");
            el.value = `我和 ${this.memberInfo4Client.fullName} 的小伙伴都在AskBot等你，用这个专属链接加入我们吧！ ${this.inviteLink}`;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            this.$message.success("邀请链接已复制");
        },
    },
    mounted() {
      let memberInDepartDto = localStorage.getItem('memberInDepartDto') ? JSON.parse(localStorage.getItem('memberInDepartDto')) : null;
      let departFullName = '';
      if (memberInDepartDto && memberInDepartDto.departFullName){
        let data = JSON.parse(memberInDepartDto.departFullName)
        data.forEach((item,index) => {
          departFullName += item + (index < data.length -1 ? ',' : '');
        })
      }
      this.memberInfo4Client ={
        type:'',
        bindType:'',
        memberId:memberInDepartDto ? memberInDepartDto.memberId : '',
        name:departFullName,
        fullName:departFullName
      }
    }
};
</script>

<style lang="less" scoped>
.dialog-footer-can-use-popup {
    .popup-con-box {
        width: 100%;
        display: flex;
        justify-content: center;
      .link{
        width: 300px;
        height: 150px;
        background: #FFFFFF;
        border: 1px solid #E0E6F7;
        border-radius: 5px 5px 5px 5px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 12px;
        margin-bottom: 20px;
        padding: 13px 17px;
        color: #366AFF;
        word-wrap: break-word;
        line-height: 19px;
        text-align: left;
      }
    }

    .dialog-footer {
        display: flex;
        justify-content: center;
        align-items: center;

        .promote-version,
        .buy-times {
            padding: 0 15px;
            height: 30px;
            background: #cad3f0;
            border-radius: 15px;
            background: linear-gradient(249deg, #5a7be7, #8dabff);
            color: #fff;
            cursor: pointer;
            text-align: center;
            line-height: 30px;
        }

        .buy-times {
            margin-left: 20px;
        }
    }
}
</style>