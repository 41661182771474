<template>
    <transition name="el-zoom-in-top">
        <div v-if="uploadInfoVisible" :class="['ask-app-notice',isTopSearch ? 'max-r' : 'min-r']">
            <div class="ask-upload-notify-header">
                <div class="aunh-left">
                    <span class="aunh-left-suc">
                        <i class="iconfont guoran-a-tongyichicun-duihaoyuan"></i>
                    </span>
                </div>
                <div class="aunh-content">
                    已自动同步微信聊天记录
                </div>
                <div class="aunh-right">
                    <span @click="closeUploadInfoVisible(false)" class="aunh-right-close">
                        <i class="iconfont guoran-cuohao2"></i>
                    </span>
                </div>
            </div>
            <div class="ask-upload-notify-body">
                <div class="we-chat-update-notice">
                    <span class="ask-upload-notify-text"> 是否更新知识列表?</span>
                    <el-button @click="noticeUpdateDocList" size="mini" type="primary" plain round>更新</el-button>
                </div>
            </div>
        </div>
        <div v-if="uploadFailInfoVisible" :class="['ask-app-notice',isTopSearch ? 'max-r' : 'min-r']">
            <div class="ask-upload-notify-header">
                <div class="aunh-left">
                    <span class="aunh-left-err">
                        <i class="iconfont guoran-tishi"></i>
                    </span>
                </div>
                <div class="aunh-content">
                    微信知识同步失败
                </div>
                <div class="aunh-right">
                    <span @click="closeUploadFailInfoVisible(false)" class="aunh-right-close">
                        <i class="iconfont guoran-cuohao2"></i>
                    </span>
                </div>
            </div>
            <div class="ask-upload-notify-body">
                <div class="we-chat-update-notice-light">
                    <span class="ask-upload-notify-text-light">{{uploadFailInfoTip}}</span>
                    <el-button @click="updatePayVersion" size="mini" type="primary" plain round>立即升级</el-button>
                </div>
            </div>
        </div>
        <div v-if="joinCompanyVisible" class="ask-app-notice join-company">
            <div v-for="(item,index) in joinCompanyList" :key="index" class="join-company-item">
                <div class="ask-upload-notify-header">
                    <div class="join-company-time">{{item.time}}</div>
                    <div class="aunh-right">
                        <span @click="closeJoinVisible(index)" class="aunh-right-close">
                            <i class="iconfont guoran-cuohao2"></i>
                        </span>
                    </div>
                </div>
                <div class="ask-upload-notify-body">
                    <span class="join-company-tips" v-html="item.tips"></span>
                </div>
            </div>
        </div>
         <div v-if="applyVisible" class="ask-app-notice apply">
            <div v-for="(item,index) in applyNoticeList" :key="index" class="join-company-item">
                <div class="ask-upload-notify-header">
                    <div class="join-company-time">{{item.time}}</div>
                    <div class="aunh-right">
                        <span @click="closeApplyVisible(index)" class="aunh-right-close">
                            <i class="iconfont guoran-cuohao2"></i>
                        </span>
                    </div>
                </div>
                <div class="ask-upload-notify-body">
                    <span class="join-company-tips" v-html="item.tips"></span>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { requestUrl } from "../../http/requestUrl";
export default {
    name: "AppNotice",
    props: {
        // 模板列表
        noticeType: {
            type: String,
            default: () => {
                return 'notice'
            }
        }
    },
    data() {
        return {
            uploadInfoVisible: false,
            updateBtnVisible: false,
            uploadFailInfoVisible: false,
            updateFailBtnVisible: false,
            uploadFailInfoTip: "版本容量不足，请升级版本享受更多权益",
            joinCompanyVisible:false, // 加入企业审核
            joinCompanyList:[],
            timer:null,
            applyVisible:false, // 审批推送
            applyNoticeList:[],
            timerApply:null
        }
    },
    watch: {
        uploadInfoVisible(n){
            this.updateUploadInfoVisible(n)
        },
        joinCompanyVisible(n){
            if(n){
                this.openTimer();
            }
        },
        applyVisible(n){
            if(n){
                this.openTimerApply();
            }
        },
    },
    computed:{
        ...mapGetters('apphandle', {
            // 助手显隐
            isTopSearch: 'getIsTopSearch',
        }),
    },
    methods: {
        openTimer(){
            this.timer = setInterval(() => {
                this.joinCompanyList.forEach((item,index) => {
                    item.timer--;
                    if(item.timer <= 0){
                        this.joinCompanyList.splice(index,1);
                    }
                })
                if(this.joinCompanyList.length === 0){
                    this.joinCompanyVisible = false;
                    clearInterval(this.timer)
                }
            },1000)
        },
        openTimerApply(){
            this.timerApply = setInterval(() => {
                this.applyNoticeList.forEach((item,index) => {
                    item.timer--;
                    if(item.timer <= 0){
                        this.applyNoticeList.splice(index,1);
                    }
                })
                if(this.applyNoticeList.length === 0){
                    this.applyVisible = false;
                    clearInterval(this.timerApply)
                }
            },1000)
        },
        ...mapActions('apphandle', [
            'updateUploadInfoVisible'
        ]),
        /**
         * 关闭提示消息
         * @method closeUploadInfoVisible
         * @param {boolean} visible 是否展示提示
         * @return 发送消息调用父组件相应方法
         */
        closeUploadInfoVisible(visible, callback) {
            this.uploadInfoVisible = visible;
            console.log(67, this.$route.path);
            if (this.$route.path === '/index') {
                this.updateBtnVisible = true;
            } else {
                this.updateBtnVisible = false;
            }
            if (typeof (callback) == 'function') {
                callback();
            }
        },
        /**
         * 是否展示同步失败提示消息
         * @method closeUploadFailInfoVisible
         * @param {boolean} visible 是否展示提示
         * @return 发送消息调用父组件相应方法
         */
        closeUploadFailInfoVisible(visible, failType){
            this.uploadFailInfoVisible = visible;
            console.log(93, this.$route.path, visible, failType);
            if (this.$route.path === '/payVersion') {
                this.updateFailBtnVisible = false;
            } else {
                this.updateFailBtnVisible = true;
            }
            if (visible) {
                if (failType === "VIP_CHAT_SYNC_FAIL") {
                    this.uploadFailInfoTip = "版本容量不足，请升级版本享受更多权益";
                }
                if (failType === "NO_VIP_CHAT_SYNC_FAIL") {
                    this.uploadFailInfoTip = "文件大小超过25M，请升级版本享受更多权益";
                }
            }
        },
        /**
         * 关闭提示并跳转至升级界面
         * @method updatePayVersion
         * @param {}
         * @return
         */
        updatePayVersion(){
            this.uploadFailInfoVisible = false;
            this.$router.push({name:"payVersionView"});
        },
        noticeUpdateDocList() {
            if (this.$route.path !== '/index' || (this.$route.path === '/index' && this.$route.query.isNavSearchDoc == 1)) {
                this.$emit("noticeUpdateDocList", new Date().getTime(),false);
            } else {
                this.messageFn('success', '更新成功，请前往首页查看！');
                this.$emit("noticeUpdateDocList", new Date().getTime(),false);
            }
        },
         // 时间戳转换成时间
		getDataTime(times){
			if (!times) {
				return '-'
			}
			let getBYT = (num) => {
				if(num == 0) {
					return '00'
				} else if(num < 10) {
					return '0' + num;
				} else {
					return num;
				}
			}
			let date = new Date(times);
            let m = getBYT(date.getMonth() + 1 )
            let D = getBYT(date.getDate() )
            let H = getBYT(date.getHours() )
            let M = getBYT(date.getMinutes() );
            if (date.toDateString() === new Date().toDateString()) {
                return H + '时' + M + '分'
            } else if (date < new Date()){
                return m + '月' + D + '日' + " " +  H + '时' + M + '分'
            }
		},
        closeJoinVisible(index){
            this.joinCompanyList.splice(index,1)
        },
        closeApplyVisible(index){
            this.applyNoticeList.splice(index,1)
        },
        // 获取流量/容量审批记录
        getFlowAndCapacityList(eventData){
            console.log(eventData,'eventData');
            let ids = [];
            if(eventData && eventData.id){
                ids.push(eventData.id);
                this.applyNoticeList = [...this.applyNoticeList,...[{
                    timer: 5,
                    id:eventData.id,
                    type:eventData.type,
                    time: this.getDataTime(eventData.createTime)
                }]];
                this.applyNoticeList = this.handlerType(this.applyNoticeList)
                this.markApply(ids);
                this.applyVisible = this.applyNoticeList.length === 0 ? false : true;
            } else {
                this.$http.get(requestUrl.company.getFlowAndCapacityList + "?client=web").then((res) => {
                    if (res.data.code === "0") {
                        if(res.data.data && res.data.data.length > 0 ){
                            res.data.data.forEach(item => {
                                ids.push(item.id);
                                item.time = this.getDataTime(item.handleTime);
                                item.type = item.handleType;
                                item.timer = 5;
                                this.applyNoticeList.push(item)
                            })
                            this.markApply(ids);
                        }
                        this.applyNoticeList = this.handlerType(this.applyNoticeList)
                        this.applyVisible = this.applyNoticeList.length === 0 ? false : true;
                    } else {
                        this.messageFn('error', res.message);
                    }
                });
            }
            
            
        },
        // 获取加入企业记录
        getJoinCompanyList(eventData){
            let ids = [];
            if(eventData && eventData.id){
                ids.push(eventData.id);
                this.joinCompanyList = [...this.joinCompanyList,...[{
                    id:eventData.id,
                    type:eventData.type,
                    time: this.getDataTime(eventData.createTime),
                    payload:eventData.payload,
                    timer:5
                }]];
                this.joinCompanyList = this.handlerType(this.joinCompanyList)
                this.markJoinCompany(ids);
                this.joinCompanyVisible = this.joinCompanyList.length === 0 ? false : true;
            } else {
                this.$http.get(requestUrl.company.getJoinCompanyList + "?client=web").then((res) => {
                    if (res.data.code === "0") {
                        if(res.data.data && res.data.data.length > 0 ){
                            res.data.data.forEach(item => {
                                ids.push(item.id);
                                item.payload = item.handleResult || '';
                                item.time = this.getDataTime(item.handleTime);
                                item.type = item.handleType;
                                item.payload = item.payload || '--';
                                item.timer = 5;
                                this.joinCompanyList.push(item) 
                            })
                            this.markJoinCompany(ids);
                        }
                        this.joinCompanyList = this.handlerType(this.joinCompanyList)
                        this.joinCompanyVisible = this.joinCompanyList.length === 0 ? false : true;
                        if(this.joinCompanyVisible){
                            this.$emit('updateCompanyList')
                        }
                    } else {
                        this.messageFn('error', res.message);
                    }
                });
            }
        },
        handlerType(list){
            list.forEach(item => {
                switch (item.type) {
                    case 'FLOW_DILATATION_PASS':
                        item.tips = '流量申请审批已通过审核!';
                    break;
                    case 'FLOW_DILATATION_REFUSE':
                        item.tips = '流量申请审批已拒绝审核';
                    break;
                    case 'CAPACITY_DILATATION_PASS':
                        item.tips = '容量申请审批已通过审核!';
                    break;
                    case 'CAPACITY_DILATATION_REFUSE':
                        item.tips = '容量申请审批已拒绝审核';
                    break;
                    case 'JOIN_COMPANY_WAIT_AUDIT':
                        item.tips = '已申请加入<span style="color: #2c3e50;">' + item.payload+'</span> ，请等待企业管理员审核';
                    break;
                    case 'JOIN_COMPANY_AUDIT_PASS':
                        item.tips = '管理员同意了您加入<span style="color: #2c3e50;">' + item.payload+'</span> 的申请';
                    break;
                    case 'JOIN_COMPANY_AUDIT_REFUSE':
                        item.tips = '管理员拒绝了您加入<span style="color: #2c3e50;">' + item.payload+'</span> 的申请';
                    break;
                    case 'JOIN_COMPANY_SUCCESS':
                        item.tips = '您已成功加入 <span style="color: #2c3e50;">' + item.payload+'</span>';
                    break;
                }
            })
            return list
        },
        markApply(ids){
            this.$http.post(requestUrl.company.markFlowAndCapacity,{
                list:ids,
                client: "web"
            }).then((res) => {
                if (res.data.code === "0") {
                    console.log('markApply',res);
                }
            })
        },
        markJoinCompany(ids){
            this.$http.post(requestUrl.company.markJoinCompany,{
                list:ids,
                client: "web"
            }).then((res) => {
                if (res.data.code === "0") {
                    console.log('markJoinCompany',res);
                }
            })
        },
        
    },
    mounted() {

    }
}
</script>
<style lang="less" scoped>
@import "./../../assets/less/common.less";

.ask-app-notice {
    z-index: 10;
    position: absolute;
    min-height: 60px;
    top: 18px;
    right: 16px;
    width: 280px;
    background: #FFFFFF;
    // box-shadow: 0px 1px 9px 0px rgba(57, 63, 79, 0.21);
    border-radius: 5px;
    &.max-r{
        right: 403px;
    }
    &.min-r{
        right: 16px;
    }

    .ask-upload-notify-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 60px;
        line-height: 60px;
       

        .aunh-left {
            flex: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 60px;
            padding-left: 16px;
            font-size: 14px;

            .aunh-left-suc {
                color: #04B051;
                font-weight: 700;
                margin-right: 5px;
            }

            .aunh-left-err {
                color: #FE5965;
                font-weight: 700;
                margin-right: 5px;
            }
        }

        .join-company-time{
            flex: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;
            line-height: 50px;
            color: #A9B3C6;
            font-size: 12px;
        }

        .aunh-content {
            flex: auto;
            text-align: left;
            font-weight: bold;
            margin-left: 2px;
        }

        .aunh-right {
            flex: none;
            width: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 12px;

            .aunh-right-close {
                margin-left: 6px;
                color: #A9B3C6;
                cursor: pointer;
            }
        }
    }

    &.join-company,&.apply {
        max-height: calc(100vh - 50px);
        overflow-x: hidden;
        overflow-y: auto;
        .join-company-item{
            border-radius: 5px;
            padding: 0 0px 15px 15px;
            margin-bottom: 10px;
            box-shadow:0 1px 10px 0 rgba(57,63,79,.31);
            .ask-upload-notify-header {
                justify-content: space-between;
            }
        }
    }
    .ask-upload-notify-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;

        .we-chat-update-notice {
            padding: 0 16px 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 22px;

            .ask-upload-notify-text {
                margin-right: 4px;
            }
        }
        .we-chat-update-notice-light {
            padding: 0 0px 16px;
            font-size: 13px;

            .ask-upload-notify-text-light {
                display: block;
                margin-bottom: 16px;
            }
        }
        .join-company-tips{
            color: #A9B3C6;
            font-size: 14px;
            text-align: left;
            span{
                color: #2c3e50;
            }
        }
    }
}
</style>
  