<template>
    <div class="pdf_view" ref="pdfView" @scroll="pdfScroll">
        <!-- <iframe :src="perviewUrl" frameborder="0"></iframe> -->
        <div id="pagination" v-if="tagIds.length > 1">
            <el-pagination
                :current-page="currentPage + 1"
                @current-change="currentChange"
                @prev-click="prev"
                @next-click="next"
                layout="slot, prev, pager, next"
                :page-size="1"
                :total="tagIds.length"
            >
                <span class="total-class">答案由{{ tagIds.length }}段内容生成</span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
const pdfjsLib = window['pdfjsLib']
if (pdfjsLib) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = window['pdfjs-dist/build/pdf.worker']
}
const { TextLayerBuilder } = window['pdfjs-dist/web/pdf_viewer']
import { fabric } from 'fabric'
// EventBus pdf_viewer 支持绑定自定义事件，一版不做

import { requestUrl } from "../http/requestUrl";
import { newInitWaterMark } from "@/common/common";
export default {
    name: 'pdfView',
    props: ['tagIds','textWatermarkStr'],
    data () {
        return {
            url: '',
            // pdfjsLib: window['pdfjs-dist/build/pdf'],
            // pdfjsLib: pdfjsLib,
            pages: [],
            pageLoadStatus: {
                WAIT: 0,
                LOADED: 1,
            },
            scale: 1,
            rotation: 0,
            pageSize: {},
            PAGE_INTVERVAL: 15,
            SLICE_COUNT: 5,
            contentView: null,
            fisrtLoad: true,
            resetLoad:false,
            TextLayerBuilder: null,
            totalPageCount: 0,
            identifyTextPostion: {
                top: 0,
                left: 0,
                width: 100,
                height: 0,
                page: 1,
                pageHeight: 0,
                pageWidth: 0,
                extractInfo: {},
                currentPageAllLine: []
            },
            currentPageAllLine: [],
            pdfUrl: '',
            cachePdf: [],
            newViewer: null,
            currentPage: 0,
            changetoolbar: false,
            allTr: [],
            preViewType: 'pdf',
            mark: false,
            canvasFabric: [],
            fabricDetailFlag: false,
            selectedRect: {},
            rectParma: {
                type: 'TEXT',
                color: '#366AFF',
                data: {}
            },
            colorList: ['#366AFF', '#FFFF09', '#FD595F', '#FC7306', '#25B356'],
            rectDomList: [],
            markPageCopy: []
        }
    },
    methods: {
        getpdfResloutePage (pdfResloute) {
            // 根据当前页面宽度设置缩放比例
            if (pdfResloute.pageWidth == 0) {
                this.scale = 1
            } else {
                this.scale = this.$refs.pdfView.clientWidth / pdfResloute.pageWidth
            }
            // 从后端获取到当前分片后所有的pdf页码，初始化数组，数组下{} 对应每页pdf文件
            this.pdfUrl = pdfResloute.publicPageFileUrl.substring(0, pdfResloute.publicPageFileUrl.lastIndexOf('/') + 1)
            this.initPages(pdfResloute.total)
            // 定位功能，加载对应页码位置
            this.loadPdfData(pdfResloute.page)
        },
        async loadPdfData (loadPage) {
            if (this.pages[loadPage - 1] && ((this.pages[loadPage - 1].dom && this.pages[loadPage - 1].dom.children.length > 0) || this.pages[loadPage - 1].loadStatus)) {
                return
            } else {
                if (this.changetoolbar) {
                    this.$nextTick(() => {
                        this.renderHighlights()
                    })
                    this.changetoolbar = false
                }
            }
            // pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/legacy/build/pdf.worker.entry.js");
            // 拿到第一个分片
            const { url } = await this.fetchPdfFragment(loadPage);
            let loadingTask = pdfjsLib.getDocument(url)
            loadingTask.promise.then(async (pdfDoc) => {
                // 将已经下载的分片保存到 pages 数组中
                // for (let i = 0; i < pdfDoc.numPages; i += 1) {
                //     const pageIndex = startPage + i;
                //     const page = this.pages[pageIndex - 1];
                //     this.pages[pageIndex - 1].url = url
                //     // 不在缓存列表内，重新获取本页pdf
                //     if (page.loadStatus !== this.pageLoadStatus.LOADED) {
                //         pdfDoc.getPage(i + 1).then((pdfPage) => {
                //             page.pdfPage = pdfPage;
                //             page.loadStatus = this.pageLoadStatus.LOADED;
                //             // 通知可以进行渲染了
                //             this.startRenderPages(pdfPage, page, pageIndex)
                //         });
                //     } else {
                //         if (this.changetoolbar) {
                //             this.$nextTick(() => {
                //                 this.renderHighlights()
                //             })
                //             this.changetoolbar = false
                //         }
                //     }
                // }
                const page = this.pages[loadPage - 1]
                await pdfDoc.getPage(1).then(async (pdfPage) => {
                    this.pages[loadPage - 1].loadStatus = true
                    this.pages[loadPage - 1].pdfPage = pdfPage;
                    // 通知可以进行渲染了
                    await this.startRenderPages(pdfPage, page, loadPage)
                });
            });

        },
        initPages (totalPage) {
            // const pages = [];
            this.totalPageCount = totalPage
            for (let i = 0; i < totalPage; i += 1) {
                this.pages.push({
                    pageNo: i + 1,
                    loadStatus: false,
                    pdfPage: null,
                    url: '',
                    dom: null
                });
            }
        },
        async fetchPdfFragment (pageIndex) {
            // 置换加签后的文件地址。
            let obj = {}
            await this.$http.post(
                '/knowledge-api/temporary-certificate/or-origin?expired=30',
                this.pdfUrl + pageIndex + '.pdf',
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(async res => {
                    if (res.data) {
                        // 最后返回一个 包含这4个参数的对象
                        console.log(res.data, "res.data.replace");
                        obj = await {
                            "startPage": pageIndex, // 分片的开始页码
                            "endPage": pageIndex + 5, // 分片结束页码
                            "totalPage": this.totalPageCount, // pdf 总页数
                            "url": res.data // 分片内容下载地址
                        }
                    }
                })
            return obj
        },
        startRenderPages (pdfPage, page, pageIndex) {
            if (Object.keys(this.pageSize).length == 0) {
                const viewport = pdfPage.getViewport({
                    scale: this.scale, // 缩放的比例
                    rotation: this.rotation, // 旋转的角度
                });
                console.log(viewport, 'viewportviewport');
                // 记录pdf页面高度
                const pageSize = {
                    width: viewport.width,
                    height: viewport.height,
                }
                this.pageSize = pageSize
                // 创建内容绘制区，并设置大小
                this.contentView.style.width = `${pageSize.width}px`;
                this.contentView.style.height = `${(this.totalPageCount * (pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL}px`;
                this.contentView.style.margin = '0 auto 0'
                this.contentView.style.position = 'relative'
                this.contentView.setAttribute('id', 'contentView')
                // contentView.style.overflowY = 'auto'
                this.$refs.pdfView.appendChild(this.contentView);
              if (this.textWatermarkStr){
                newInitWaterMark('contentView',this.textWatermarkStr)
              }
            }
            this.renderPages(pageIndex)
        },
        renderPageContent (page, pageIndex) {
            const { pdfPage, pageNo, dom } = page;
            // dom 元素已存在，无须重新渲染，直接返回
            if (dom && dom.children.length != 0) {
                return;
            }
            page.loading = true
            const viewport = pdfPage.getViewport({
                scale: this.scale,
                rotation: this.rotation,
            });
            // 创建新的canvas
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = this.pageSize.height;
            canvas.width = this.pageSize.width;
            canvas.style.position = 'relative'
            canvas.style.top = -3 + 'px'
            // const c = document.createElement('canvas');
            // c.width = this.pageSize.width
            // c.height = this.pageSize.height 
            // c.setAttribute('id', `canvas${pageIndex}`);
            // c.setAttribute('class', 'fabricCanvas');
            // 创建渲染的dom
            const pageDom = document.createElement('div');
            pageDom.style.position = 'absolute';
            pageDom.style.top = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL}px`;
            pageDom.style.width = `${this.pageSize.width}px`;
            pageDom.style.height = `${this.pageSize.height}px`;
            pageDom.setAttribute('data-id', 'page' + pageNo)

            // pageDom.appendChild(c)
            pageDom.appendChild(canvas);
            // 渲染内容
            let renderContext = {
                canvasContext: context,
                viewport: viewport,
            }
            pdfPage.render(renderContext).promise.then(() => {
                console.log(pdfPage.getTextContent(), 'getTextContent');
                return pdfPage.getTextContent()
            }).then((textContent) => {
                const textLayerDiv = document.createElement('div');
                textLayerDiv.setAttribute('class', 'textLayer');
                // 将文本图层div添加至每页pdf的div中
                // 创建新的TextLayerBuilder实例
                let textLayer = new TextLayerBuilder({
                    textLayerDiv: textLayerDiv,
                    pageIndex: pdfPage._pageIndex,
                    viewport: viewport,
                });
                console.log(textLayer,'textLayertextLayer');
                let findPage = this.currentPageAllLine.find(l => { return l.page == pageIndex })
                let rectdomTop = 0
                if (findPage) {
                    let AllLines = findPage.allLines
                    // setTimeout(() => {
                    if (AllLines.length > 0) {
                        for (let j = 0; j < AllLines.length; j++) {
                            let lines = AllLines[j].lines
                            let rectdom = document.createElement('div')
                            rectdom.setAttribute('react-count', AllLines[j].pageCount);
                            rectdom.style.position = 'absolute';
                            rectdom.style.top = 0
                            rectdom.style.left = 0
                            rectdom.classList.add('rectdom')
                            for (let index = 0; index < lines.length; index++) {
                                if (!/^\s+$/g.test(lines[index].content)) {
                                    let postionArr = lines[index].location
                                    let div = document.createElement('div')
                                    div.style.position = 'absolute';
                                    div.style.left = postionArr[0] * this.scale + 'px',
                                        // 后端返回的坐标有基线对齐的问题，top 值是后端算好（基线top - 文字高度），在此加上文字高度的 1/9 （大致比例）为实际展示出文字的top值
                                        div.style.top = postionArr[1] * this.scale + 'px'
                                    div.style.height = postionArr[3] * this.scale + 'px';
                                    div.style.width = postionArr[2] * this.scale + 'px'
                                    div.style.backgroundColor = 'rgba(54, 106, 255, 0.3)'
                                    div.classList.add('lineHeight')
                                    rectdom.appendChild(div)
                                    if (index == 0 && j == 0) {
                                        rectdomTop = postionArr[1] * this.scale
                                    }
                                }
                            }
                            if (rectdom.children.length > 0) {
                                pageDom.appendChild(rectdom)
                            }
                        }
                    }
                    // }, 0)
                }
                textLayer.setTextContent(textContent);
                textLayer.render()
                pageDom.appendChild(textLayerDiv);
                let backgroundDom = document.getElementById('backgroundLoad' + pageNo)
                if (backgroundDom) {
                    this.contentView.removeChild(backgroundDom);
                }
                page.dom = pageDom;
                this.contentView.appendChild(pageDom);
                // page.c = new fabric.Canvas('canvas' + pageIndex)
                // this.fabricRectDom(page.c, page)
                if (this.changetoolbar) {
                    setTimeout(() => {
                        console.log('========');
                        this.renderHighlights()
                        this.changetoolbar = false
                    }, 100)
                }
                console.log(this.fisrtLoad, 'this.fisrtLoad');
                if (this.fisrtLoad || this.resetLoad) {
                    setTimeout(() => {
                        if (this.$refs.pdfView.clientHeight - (this.pageSize.height + this.PAGE_INTVERVAL) > 0 && pageIndex == 1) {
                            const height = this.$refs.pdfView.clientHeight;
                            let startNum = 0
                            let endNum = 0
                            startNum = Math.ceil(this.$refs.pdfView.scrollTop / (this.pageSize.height + this.PAGE_INTVERVAL))
                            endNum = startNum + Math.ceil(height / (this.pageSize.height + this.PAGE_INTVERVAL))
                            for (let pageIndex = startNum; pageIndex <= endNum; pageIndex++) {
                                if (pageIndex > 0 && pageIndex <= this.pages.length) {
                                    this.loadPdfData(pageIndex)
                                }
                            }
                        }
                        if (this.$refs.pdfView.scrollTop == Math.floor((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL))) {
                            this.$refs.pdfView.scrollTop = rectdomTop
                            this.fisrtLoad = false
                        } else {
                            this.$refs.pdfView.scrollTop = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL) + rectdomTop)}`
                            this.fisrtLoad = false
                        }
                        this.resetLoad = false
                    }, 150)
                }
            })

        },
        // 监听容器的滚动事件，触发 scrollPdf 方法
        // 这里加了防抖保证不会一次产生过多请求
        debounceScrollPdf: _.debounce(function (e, that) {
            if (this.fisrtLoad) {
                this.fisrtLoad = false
                return
            }
            const scrollTop = e.target.scrollTop;
            const height = e.target.clientHeight;
            let startNum = 0
            let endNum = 0
            startNum = Math.ceil(scrollTop / (that.pageSize.height + that.PAGE_INTVERVAL))
            endNum = startNum + Math.ceil(height / (that.pageSize.height + that.PAGE_INTVERVAL))
            for (let pageIndex = startNum; pageIndex <= endNum; pageIndex++) {
                if (pageIndex > 0 && pageIndex <= that.pages.length) {
                    that.loadPdfData(pageIndex)
                }
            }
        }, 200),
        directScrolling (e, that) {
            if (this.fisrtLoad) {
                this.fisrtLoad = false
                return
            }
            const scrollTop = e.target.scrollTop;
            const height = e.target.clientHeight;
            // 根据内容可视区域中心点计算页码, 没有滚动时，指向第一页
            const pageIndex = scrollTop > 0 ?
                Math.ceil((scrollTop + (height / 2)) / (that.pageSize.height + that.PAGE_INTVERVAL)) :
                1;
            this.loadPdfData(pageIndex)
        },
        pdfScroll (e) {
            if (this.preViewType !== 'pdf') {
                return
            }
            this.debounceScrollPdf(e, this)
        },
        // 分片每次只做一次处理，所以不考虑多片情况
        loadBefore (pageIndex) {
            this.loadPdfData(pageIndex)
        },
        loadAfter (pageIndex) {
            this.loadPdfData(pageIndex)
        },
        // 首先我们获取到需要渲染的范围
        // 根据当前的可视范围内的页码，我们前后只保留 8 页
        getRenderScope (pageIndex) {
            const pagesToRender = [];
            let i = pageIndex - 1;
            let j = pageIndex + 1;
            // pageIndex - 1 表示当前页码数 对应的下标位置
            pagesToRender.push(this.pages[pageIndex - 1]);
            while (pagesToRender.length < 10 && pagesToRender.length < this.pages.length) {
                if (i > 0) {
                    pagesToRender.push(this.pages[i - 1]);
                    i -= 1;
                }
                if (pagesToRender.length >= 10) {
                    break;
                }
                if (j <= this.pages.length) {
                    pagesToRender.push(this.pages[j - 1]);
                    j += 1;
                }
            }
            return pagesToRender;
        },
        // 渲染需要展示的页面，不需展示的页码将其清除
        renderPages (pageIndex) {
            const pagesToRender = this.getRenderScope(pageIndex);
            for (const i of this.pages) {
                if (pagesToRender.some(p => { return p.pageNo == i.pageNo })) {
                    if (i.loadStatus === true) {
                        this.renderPageContent(i, i.pageNo)
                    } else {
                        this.renderPageLoading(i, i.pageNo)
                    }
                } else {
                    this.clearPage(i);
                }
            }
        },
        // 清除页面 dom
        clearPage (page) {
            if (page.dom) {
                if(this.contentView.contains(page.dom)) {
                    this.contentView.removeChild(page.dom);
                }
                page.loadStatus = false
                page.loading = false
                page.dom = undefined;
            }
        },
        // 页面正在下载时渲染loading视图
        renderPageLoading (page) {
            const { pageNo, dom } = page;
            if (dom && dom.children.length != 0) {
                return;
            }
            let backgroundDom = document.getElementById('backgroundLoad' + pageNo)
            if (this.contentView.contains(backgroundDom)) {
                return
            }
            const pageDom = document.createElement('div');
            pageDom.style.width = `${this.pageSize.width}px`;
            pageDom.style.height = `${this.pageSize.height}px`;
            pageDom.style.position = 'absolute';
            pageDom.style.top = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL
                }px`;
            pageDom.style.backgroundImage = `url('https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-common/images/loading.gif')`
            pageDom.style.backgroundPosition = 'center'
            pageDom.style.backgroundRepeat = 'no-repeat'
            pageDom.style.backgroundColor = '#FFF'
            pageDom.setAttribute('id', 'backgroundLoad' + pageNo)
            page.dom = pageDom;
            this.contentView.appendChild(pageDom);
        },
        prev () {
            this.currentPage--
            if (this.currentPage < 0) {
                // this.currentPage = this.tagIds.length - 1
                this.currentPage = 0
            }
            if (this.preViewType == 'pdf') {
                this.scrollToUplaodePage(this.currentPage)
            } else {
                this.scrollToExcalTop(this.currentPage)
            }
            // this.getpdfResloutePage(this.cachePdf[this.currentPage - 1])
        },
        next () {
            this.currentPage++
            if (this.currentPage >= this.tagIds.length) {
                this.currentPage = 0
            }
            if (this.preViewType == 'pdf') {
                this.scrollToUplaodePage(this.currentPage)
            } else {
                this.scrollToExcalTop(this.currentPage)
            }
        },
        currentChange (value) {
            this.currentPage = value - 1
            if (this.preViewType == 'pdf') {
                this.scrollToUplaodePage(this.currentPage)
            } else {
                this.scrollToExcalTop(this.currentPage)
            }
        },
        scrollToUplaodePage (currentPage) {
            this.changetoolbar = true
            if (this.preViewType !== 'pdf') {
                return
            }
            console.log(this.cachePdf[currentPage],this.cachePdf , 'this.cachePdfthis.cachePdf' );
            let pdfResloute = this.cachePdf[currentPage]
            // this.identifyTextPostion.extractInfo = pdfResloute.extractInfo
            // this.identifyTextPostion.left = pdfResloute.extractInfo.location[0]
            // this.identifyTextPostion.top = pdfResloute.extractInfo.location[1]
            // this.identifyTextPostion.width = pdfResloute.extractInfo.location[2]
            // this.identifyTextPostion.height = pdfResloute.extractInfo.location[3]
            // this.identifyTextPostion.page = pdfResloute.page
            // this.identifyTextPostion.pageHeight = pdfResloute.pageHeight
            // this.identifyTextPostion.pageWidth = pdfResloute.pageWidth
            // console.log( Math.floor((pdfResloute.page - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + (this.pageSize.height + this.PAGE_INTVERVAL * 2) ,this.$refs.pdfView.scrollHeight);
            // debugger
            // if(this.$refs.pdfView.scrollTop == Math.floor((pdfResloute.page - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) ||
            //     Math.floor((pdfResloute.page - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + (this.pageSize.height + this.PAGE_INTVERVAL) >= this.$refs.pdfView.scrollHeight) {
            //     this.renderHighlights()
            // } else {
            // 在当前段落在最后一页pdf时，根据计算的高度并不能触发滚动，在此执行重新渲染方法，非次情况会执行两次，待优化
            this.$nextTick(() => {
                this.renderHighlights()
            })
            this.$refs.pdfView.scrollTop = `${((pdfResloute.page - 1) * (this.pageSize.height + this.PAGE_INTVERVAL))}`
            // }
        },
        scrollToExcalTop (currentPage) {
            for (let index = 0; index < this.allTr.length; index++) {
                if (index == currentPage) {
                    if (this.allTr[index] && this.allTr[index].children){
                      Array.from(this.allTr[index].children).forEach(item => {
                        item.style.background = 'rgba(255, 136, 0, 0.6)'
                      })
                      this.$refs.pdfView.scrollTop = this.allTr[index].offsetTop
                    }
                } else {
                  if (this.allTr[index] && this.allTr[index].children){
                    Array.from(this.allTr[index].children).forEach(item => {
                      item.style.background = 'rgba(54, 106, 255, 0.6)'
                    })
                  }
                }
            }
        },
        // pdf是否需要重新渲染高亮位置
        renderHighlights () {
            let lineHeightDom = Array.from(document.getElementsByClassName('rectdom'))
            console.log(lineHeightDom, this.currentPage, 'this.currentPage');
            if (lineHeightDom) {
                lineHeightDom.forEach((d) => {
                    for (let i = 0; i < d.children.length; i++) {
                        if (d.getAttribute('react-count') == this.currentPage) {
                            d.children[i].style.backgroundColor = 'rgba(255, 136, 0, 0.3)'
                        } else {
                            d.children[i].style.backgroundColor = 'rgba(54, 106, 255, 0.3)'
                        }
                    }
                })
            }
        },
        displayHiglight (pageIndex) {
            let lineHeightDom = Array.from(document.getElementsByClassName('rectdom'))
            if (lineHeightDom) {
                lineHeightDom.forEach((d) => {
                    if (d.getAttribute('page-index') == pageIndex) {
                        d.style.display = 'none'
                    }
                })
            }
        },
        // 前端暂时缓存多页
        autoLoadMore (pageIndex) {
            let pdfResloute = this.cachePdf.find(cache => {
                return cache.page == pageIndex
            })
            if (pdfResloute) {
                this.getpdfResloutePage(pdfResloute)
            } else {
                this.loadPdfData(pageIndex)
            }
        },
        setPageAllLine (arr) {
            this.currentPageAllLine = []
            arr.forEach((item, index) => {
                let i = this.currentPageAllLine.findIndex(l => { return l.page && l.page == item.page })
                if (i != -1) {
                    // this.currentPageAllLine[i].allLines.lines.push(item.extractInfo.lines)
                    this.currentPageAllLine[i].allLines.push({
                        pageCount: index,
                        lines: item.extractInfo.lines ? item.extractInfo.lines  : [{
                            content:"测试内容填充，不必理会",
                            location:item.extractInfo.location
                        }]
                    })
                } else {
                    this.currentPageAllLine.push({
                        page: item.page,
                        allLines: [{
                                pageCount: index,
                                lines: item.extractInfo.lines ? item.extractInfo.lines : [{
                                content:"测试内容填充，不必理会",
                                location:item.extractInfo.location
                            }]
                        }],
                    })
                }
            })
        },
        // 废弃⚠️
        openMark () {
            console.log('hhhhh');
            this.mark = !this.mark
            if (this.mark) {
                this.canvasFabric = Array.from(document.getElementsByClassName('canvas-container'))
                this.canvasFabric.forEach(c => {
                    c.style.zIndex = 99999999
                })
                this.fabricDetailFlag = true
            } else {
                this.canvasFabric = Array.from(document.getElementsByClassName('canvas-container'))
                this.canvasFabric.forEach(c => {
                    c.style.zIndex = 0
                })
                this.fabricDetailFlag = false
            }
        },
        // 废弃⚠️
        fabricRectDom (c, { pageNo, url }) {
            let downPoint = null
            let rectSelect = false
            c.on('mouse:down', (e) => {
                if (!rectSelect) {
                    downPoint = e.absolutePointer
                }
                if (e.target == null) {
                    rectSelect = false
                }
                console.log(e);
            })
            c.on('mouse:up', ({ pointer, target }) => {
                if (JSON.stringify(downPoint) === JSON.stringify(pointer) || rectSelect) {
                    if (target == null) {
                        rectSelect = false
                    }
                    return
                }
                let top = Math.min(downPoint.y, pointer.y)
                let left = Math.min(downPoint.x, pointer.x)
                let width = Math.abs(downPoint.x - pointer.x)
                let height = Math.abs(downPoint.y - pointer.y)
                let rect = new fabric.Rect({
                    left: left,
                    top: top,
                    fill: '#366AFF',
                    opacity: 0.1,
                    width: width,
                    height: height
                });
                c.add(rect)
                this.pages[pageNo].c = c
                console.log(this.pages[pageNo].c);
                rect.on('selected', (s) => {
                    rectSelect = true
                    this.$set(this.selectedRect, 'sorcue', c)
                    this.$set(this.selectedRect, 'target', s.target)
                    this.$set(this.selectedRect, 'Index', pageNo)
                    this.$set(this.selectedRect, 'url', url)

                })
                this.rectDomList.push(rect)

                // downPoint = null
            })
        },
        changeData () {

        },
        changeColor (color) {
            this.rectParma.color = color
            this.selectedRect.target.set({
                fill: color
            })
            this.selectedRect.sorcue.renderAll.bind(this.selectedRect.sorcue)
        },
        // 根据当前位置 获取文档识别内容
        getRectParma () {
            let url = ''
            if (this.rectParma.type == 'TEXT') {
                url = '/knowledge-api/correction/extract-pdf-text'
            } else {
                url = '/knowledge-api/correction/extract-pdf-table'
            }
            console.log(this.selectedRect.target, this.scale);
            this.$http.post(url, {
                url: this.selectedRect.url,
                location: [this.selectedRect.target.left * this.scale,
                this.selectedRect.target.top * this.scale,
                this.selectedRect.target.width * this.scale,
                this.selectedRect.target.height * this.scale
                ]
            }).then(res => {
                if (res.data.code == 0) {
                    if (this.rectParma.type == 'TEXT') {
                        this.$set(this.rectParma, 'data', res.data.data)
                    }
                    if (this.rectParma.type == 'TABEL') {
                        this.$set(this.rectParma, 'data', res.data.data)
                    }
                }
            })
        },
        setRectParma () {
            console.log(this.selectedRect.Index, this.markPageCopy);
            let block = this.markPageCopy[this.selectedRect.Index - 1].block
            let b = {
                type: this.rectParma.type,
                location: [this.selectedRect.target.left * this.scale,
                this.selectedRect.target.top * this.scale,
                this.selectedRect.target.width * this.scale,
                this.selectedRect.target.height * this.scale
                ],
                text: this.rectParma.data
            }
            block.push(b)
            let url = `/knowledge-api/correction/${this.withLocationMark.id}/${this.selectedRect.Index - 1}`
            let params = {
                total: this.pages.length,
                pageHeight: this.pageSize.height,
                pageWidth: this.pageSize.width,
                pageFileUrl: this.selectedRect.url,
            }
            // todo  当前canvas 下的所有rect 包括对应的 selectedRect
            params.blocks = block
            this.$http.put(url, params).then(res => {
                if (res.data.code == 0) {
                    return
                }
            })
        },
        updateReact () {
            console.log(this.pages, 'pages');
            this.clearReat()
            for (let index = 0; index < this.pages.length; index++) {
                const page = this.pages[index];
                if (page.dom) {
                    let findPage = this.currentPageAllLine.find(l => { return l.page == page.pageNo })
                    // let rectdomTop = 0
                    if (findPage) {
                        let AllLines = findPage.allLines
                        // setTimeout(() => {
                        if (AllLines.length > 0) {
                            for (let j = 0; j < AllLines.length; j++) {
                                let lines = AllLines[j].lines
                                let rectdom = document.createElement('div')
                                rectdom.setAttribute('react-count', AllLines[j].pageCount);
                                rectdom.style.position = 'absolute';
                                rectdom.style.top = 0
                                rectdom.style.left = 0
                                rectdom.classList.add('rectdom')
                                for (let index = 0; index < lines.length; index++) {
                                    if (!/^\s+$/g.test(lines[index].content)) {
                                        let postionArr = lines[index].location
                                        let div = document.createElement('div')
                                        div.style.position = 'absolute';
                                        div.style.left = postionArr[0] * this.scale + 'px',
                                            // 后端返回的坐标有基线对齐的问题，top 值是后端算好（基线top - 文字高度），在此加上文字高度的 1/9 （大致比例）为实际展示出文字的top值
                                            div.style.top = postionArr[1] * this.scale + 'px'
                                        div.style.height = postionArr[3] * this.scale + 'px';
                                        div.style.width = postionArr[2] * this.scale + 'px'
                                        div.style.backgroundColor = 'rgba(54, 106, 255, 0.3)'
                                        div.classList.add('lineHeight')
                                        rectdom.appendChild(div)
                                        // if (index == 0 && j == 0) {
                                        //     rectdomTop = postionArr[1] * this.scale
                                        // }
                                    }
                                }
                                if (rectdom.children.length > 0) {
                                    page.dom.appendChild(rectdom)
                                }
                            }
                        }
                    }
                }
            }
            this.scrollToUplaodePage(0)
        },
        clearReat() {
            let rectdomList = document.getElementsByClassName("rectdom")
            for (let index = 0; index < rectdomList.length; index++) {
                rectdomList[index].remove()
            }  
        }
    },
    computed: {
        perviewUrl () {
            return '/web/viewer.html?file=' + '/pdflist/pdf4split-1.pdf'
        }
    },
    watch: {
        tagIds: {
            handler (value) {
                console.log(value, 'valuevaluevaluevalue');
                if (value && value.length > 0) {
                    // 在 pdf_view 下创建 所有canvs的容器
                    let c = document.getElementById('contentView')
                    if(c) {
                        this.$set(this, 'pageSize' ,{})
                        this.$set(this, 'pages', [])
                        this.fisrtLoad = true
                        this.resetLoad = true
                        c.remove()
                    }
                    this.contentView = document.createElement('div')
                    this.$http.get('/knowledge-api/knowledge/knowledge-part-location-info/list?ids=' + value.join(',')).then(res => {
                        // res.data = {"data":[{"id":"64591b7d8bb8ab1b91c65f24","knowledgeId":"64591a9c8da27649473f3b4b","mainId":"fb348d095c0b4fd7bbd37826563dac7d","page":3,"total":18,"pageHeight":540.0,"pageWidth":960.00946,"publicPageFileUrl":"https://askbot-pdf-all.oss-cn-zhangjiakou.aliyuncs.com/fb348d095c0b4fd7bbd37826563dac7d/2023/05/08/11/55/18/64591b638bb8ab1b91c65eed/3.pdf","extractInfo":{"location":[280.488,161.32,398.71573,61.99298],"content":"黄花城水长城旅游区位于北京市怀柔区九渡河镇境内，距北京市区65公里，是以奇而著称，融山川、碧水、古长城为一体的旅游休闲胜地。而这里的“三绝景”更是引人入","lines":[{"content":"黄花城水长城旅游区位于北京市怀柔区九渡河镇境内，距","location":[280.488,161.32,398.71573,15.9869995]},{"content":"北京市区65公里，是以奇而著称，融山川、碧水、古长","location":[283.691,184.30899,392.3055,15.9869995]},{"content":"城为一体的旅游休闲胜地。而这里的“三绝景”更是引人入","location":[282.699,207.32599,394.3033,15.9869995]}],"tagId":null}}],"code":"0","msg":null,"traceId":null}                        
                        if (res.data.code == 0) {
                            // tagIds 会按照gpt识别的生成有序的数组，前端直接按照下标的顺序取就可以了
                            // 缓存拿到的所有数据
                            this.cachePdf = res.data.data
                            let publicPageFileUrl = res.data.data[0].publicPageFileUrl
                            this.currentPage = 0
                            // console.log(publicPageFileUrl.substring(publicPageFileUrl.lastIndexOf('.')));
                            if (publicPageFileUrl.substring(publicPageFileUrl.lastIndexOf('.')) === '.pdf') {
                                this.preViewType = 'pdf'
                                this.setPageAllLine(this.cachePdf)
                                // if (c) {
                                //     this.updateReact()
                                //     return
                                // }
                                this.getpdfResloutePage(res.data.data[0])
                            } else {
                                this.preViewType = 'excal'
                                this.$refs.pdfView.style.overflow = "auto"
                                this.$http.post(
                                    '/knowledge-api/temporary-certificate/or-origin?expired=30',
                                    publicPageFileUrl,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    }).then(res => {
                                        // 使用原声请求方式 axios会带有不需要的请求头 
                                        let xhr = new XMLHttpRequest();
                                        xhr.open('GET', res.data, true);
                                        // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
                                        xhr.onload = ({ currentTarget }) => {
                                            // 请求完成
                                            if (currentTarget.status === 200) {   // 返回200
                                                this.contentView.innerHTML = currentTarget.response
                                                this.contentView.style.padding = '10px'
                                                this.$refs.pdfView.style.backgroundColor = '#FFFFFF'
                                                this.$refs.pdfView.appendChild(this.contentView)
                                                let allTr = Array.from(this.$refs.pdfView.getElementsByTagName('tr'))
                                                this.allTr = []
                                                for (let index = 0; index < allTr.length; index++) {
                                                    if (value.includes(allTr[index].getAttribute('tag-id'))) {
                                                        allTr[index].style.position = 'relative'
                                                        this.allTr.push(allTr[index])
                                                    }
                                                }
                                                this.currentChange(1)

                                            }
                                        }
                                        xhr.send();
                                        console.log(res, requestUrl);
                                    })
                            }

                        } else {
                            let div = document.createElement('div')
                            div.innerText = '文件加载异常'
                            this.contentView.appendChild(div)
                            this.$refs.pdfView.appendChild(this.contentView)
                        }
                    })
                }
            },
            deep: true,
            immediate: true
        },
        fisrtLoad: {
            handler (value) {
                if (!value) {
                    this.$nextTick(() => {
                        this.renderHighlights()
                    })
                }
            }
        }
    },
    mounted () {
        // 在 pdf_view 下创建 所有canvs的容器
        // this.contentView = document.createElement('div')
        // // 从后端获取到当前分片后所有的pdf页码，初始化数组，数组下{} 对应每页pdf文件
        // this.initPages(8);
        // // 定位功能，加载对应页码位置
        // this.loadPdfData(1)
        // this.tagIds = ["643fcec4b0f72141997fb9e9","643fcec3b0f72141997fb9e7","643fcec3b0f72141997fb9e4","643fcec3b0f72141997fb9e5","643fcec3b0f72141997fb9e1"]
    }
}
</script>
<style lang="less" scoped>
.pdf_view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-color: #f5f7fb;
    padding-bottom: 50px;
    padding-right: 8px;
    box-sizing: border-box;

    // > div {
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;
    //     overflow-y: auto;
    //     position: relative;
    // }
    > iframe {
        width: 100%;
        height: 100%;
    }

    .doc-handle-box {
        background: rgba(52, 68, 115, 0.8);
        border-radius: 5px;
        width: 260px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: white;
        height: 36px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;

        .dhb-cell {
            height: 14px;
            line-height: 14px;
            font-size: 13px;
            flex: 1;
            border-right: solid 1px lightgrey;
            cursor: pointer;
        }

        .dhb-cell:hover {
            font-weight: bolder;
        }

        .dhb-cell-with-number {
            height: 14px;
            line-height: 14px;
            font-size: 13px;
            flex: none;
            width: 90px;
            cursor: pointer;

            .dhb-cell-count {
                font-size: 12px;
                margin-left: 6px;
                height: 20px;
                background: #ecf1ff;
                border-radius: 10px;
                padding: 0 6px;
                font-weight: bolder;

                .delimiter {
                    color: #366aff;
                    margin: 0 3px;
                }

                .num {
                    color: #366aff;
                }
            }
        }

        .dhb-cell-with-number:hover {
            font-weight: 700;
        }
    }

    #pagination {
        .total-class {
            margin-right: 13px;
            font-weight: 400;
        }

        position: absolute;
        bottom: 0px;
        right: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: white;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;
        z-index: 1000;

        /deep/.el-pagination {
            margin-right: 110px;
        }

        /deep/.el-pager {
            background: #edf0f6;
            border-radius: 15px;
        }

        /deep/.el-pagination.is-background .btn-next {
            width: 30px;
            height: 30px;
            background: #edf0f6;
            border-radius: 50%;
        }

        /deep/.el-pagination .btn-next {
            width: 30px;
            height: 30px;
            background: #edf0f6;
            border-radius: 50%;
            padding-left: 0;
            margin-left: 5px;
        }

        /deep/.el-pagination .btn-prev {
            width: 30px;
            height: 30px;
            background: #edf0f6;
            border-radius: 50%;
            padding-right: 0;
            margin-right: 5px;
        }

        /deep/.el-pagination button {
            padding: 0;
            min-width: 30px;
        }

        /deep/.el-pager li {
            background: #edf0f6;
            height: 30px;
            min-width: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #717b90;
        }

        /deep/.el-pager li:first-child {
            border-bottom-left-radius: 15px !important;
            border-top-left-radius: 15px !important;
        }

        /deep/.el-pager li:last-child {
            border-top-right-radius: 15px !important;
            border-bottom-right-radius: 15px !important;
        }

        /deep/.el-pager li.active {
            width: 30px;
            height: 30px;
            min-width: 30px;
            background: #366aff;
            border: 3px solid #a1b9ff;
            border-radius: 50%;
            line-height: 24px;
            color: white;
        }

        /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
            background: #366aff;
        }
    }
    /deep/.canvas-container {
        position: absolute !important;
    }
    .fabricDetail {
        width: 500px;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 701px;
        // background: #f5f7fb;
        background: #ffffff;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        .rect-select {
            width: 100%;
            height: 100%;
            .rect-head {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 15px;
                box-sizing: border-box;
                i {
                    cursor: pointer;
                }
            }
            .rect-content {
                width: 100%;
                height: calc(100% - 40px);
                background: #f5f7fb;
                .rect-card {
                    width: 100%;
                    // margin-bottom: 10px;
                    padding: 10px;
                    box-sizing: border-box;
                    p {
                        font-size: 14px;
                        line-height: 36px;
                        text-align: left;
                    }
                    .rect-color {
                        width: 100%;
                        height: 40px;
                        background: #f5f7fb;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        section {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                        .active {
                            border: 2px solid #000000;
                        }
                    }
                }
            }
            .rect-btn {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                box-sizing: border-box;
                .rect-get-btn {
                    padding: 5px 8px;
                    color: #ffffff;
                    background: #366aff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 40px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>