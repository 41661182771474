// 侧边、顶部 操作 通知内部组件

const state = () => ({
    // 左侧导航 根据模板筛选 模板id
    leftModeSearchId: "",
    // 顶部新建知识操作
    isTopAddDoc: false,
    fromRouteName: "", // 点击顶部新建时的路由名称
    topAddDocTimestamp: "", // 顶部新建知识最后一次操作时间戳
    // 顶部搜索操作
    isTopSearch: false,
    uploadInfoVisible:false, // 微信同步显隐
    botViewLoading: false, // 机器人对话loading
    isTestSearch: false, // 是否是测试窗形式的搜索问答
    isShowPreview: false, // 是否显示首页预览弹框
    isSwitchCompany: false, // 是否是切换企业,需要更新数据
    isNeedUpdateCompanyList: false, // 是否需要更新企业列表
    isSaveTemplate:false, // 是否触发了模版的ctrl+s保存操作
    
})

const getters = {
    getLeftModeSearchId: (state) => {
        return state.leftModeSearchId
    },
    getIsTopAddDoc: (state) => {
        return state.isTopAddDoc;
    },
    getTopAddDocTimestamp: (state) => {
        return state.topAddDocTimestamp;
    },
    getTopAddDocFromRouteName: (state) => {
        return state.fromRouteName;
    },
    getIsTopSearch: (state) => {
        return state.isTopSearch;
    },
    getUploadInfoVisible: (state) => {
        return state.uploadInfoVisible;
    },
    getBotViewLoading: (state) => {
        return state.botViewLoading;
    },
    getIsTestSearch: (state) => {
        return state.isTestSearch;
    },
    getIsShowPreview: (state) => {
        return state.isShowPreview;
    },
    getIsSwitchCompany: (state) => {
        return state.isSwitchCompany;
    },
    getIsNeedUpdateCompanyList: (state) => {
        return state.isNeedUpdateCompanyList;
    },
    getIsSaveTemplate: (state) => {
        return state.isSaveTemplate;
    },
}

const actions = {
    // 更新左侧导航筛选mode id
    updateLeftModeSearchId({ commit }, modeId) { // 取消左侧筛选状态，leftModeSearchId 置为空字符串
        console.log(modeId);
        commit('setLeftModeSearchId', modeId);
    },
    // 更新顶部新建知识操作 todo 展示或隐藏新建知识组件
    updateIsTopAddDoc({ commit }, { status, fromRouteName }) {
        console.log(status, fromRouteName);
        commit('setIsTopAddDoc', { status, fromRouteName });
    },
    // 更新顶部索索知识操作 todo 展示或隐藏搜索机器人组件
    updateIsTopSearch({ commit }, status) {
        commit('setIsTopSearch', status);
    },
    // 更新顶部点击新建知识时间戳
    updateIsTopAddDocTimestamp({ commit }, timestamp) {
        commit("setTopAddDocTimestamp", timestamp)
    },
    // 更新微信同步notice显隐
    updateUploadInfoVisible({ commit }, status) {
        commit('setUploadInfoVisible', status);
    },
    updateBotViewLoading({ commit }, status) {
        commit('setBotViewLoading', status);
    },
    updateIsTestSearch({ commit }, status) {
        commit('setIsTestSearch', status);
    },
    updateIsShowPreview({ commit }, status) {
        commit('setIsShowPreview', status);
    },
    updateIsSwitchCompany({ commit }, status) {
        commit("setIsSwitchCompany", status);
    },
    updateIsNeedUpdateCompanyList({ commit }, status) {
        commit("setIsNeedUpdateCompanyList", status);
    },
    
}

const mutations = {
    setLeftModeSearchId(state, modeId) {
        state.leftModeSearchId = modeId;
    },
    setIsTopAddDoc(state, { status, fromRouteName }) {
        state.isTopAddDoc = status;
        console.log(52, fromRouteName);
        state.fromRouteName = fromRouteName;
    },
    setIsTopSearch(state, status) {
        state.isTopSearch = status;
    },
    setTopAddDocTimestamp(state, timestamp) {
        console.log(timestamp);
        state.topAddDocTimestamp = timestamp;
    },
    setUploadInfoVisible(state, status) {
        state.uploadInfoVisible = status;
    },
    setBotViewLoading(state, status) {
        state.botViewLoading = status;
    },
    setIsTestSearch(state, status) {
        state.isTestSearch = status;
    },
    setIsShowPreview(state, status) {
        state.isShowPreview = status;
    },
    setIsSwitchCompany(state, status) {
        state.isSwitchCompany = status;
    },
    setIsNeedUpdateCompanyList(state, status) {
        state.isNeedUpdateCompanyList = status;
    },
    setIsSaveTemplate(state, status) {
        state.isSaveTemplate = status;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}