
import { requestUrl } from '../http/requestUrl'
export const modeCountCompute = {
    data () {
        return {

        };
    },
    computed: {},
    methods: {
        // 大模型次数计算
        async modeCountCompute (callback) {
            let currentVersion = this.$store.getters["user/getCurrentVersion"]
            let combinationInfo = this.$store.state.user.combinationInfo
            console.log(currentVersion, this.$store, combinationInfo, "this.$storethis.$store");
            await this.$http.post(requestUrl.upload.getCount, {
                mainId: localStorage.getItem("_mainId"),
                userId: localStorage.getItem("_uid"),
                vipType: currentVersion.vipType,
                expire: currentVersion.expire,
                userSource: localStorage.getItem('accountSource')
            }).then(async res => {
                this.$store.dispatch("user/updateCombinationInfo", res.data)
                if(callback) {
                    await callback(res)
                }
            })
        }
    }
}