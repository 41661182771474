<template>
	<popup-com v-if="isInviteFriends" @closeEvent="isInviteFriends = false" class="invite-friend">
		<div slot="popup-name" class="popup-name">邀请好友</div>
		<div slot="popup-tip">邀请好友可免费获取VIP权限</div>
		<div slot="popup-con" class="popup-con">
		<div class="invite-link">
			<div class="title">邀请链接</div>
			<div class="link-container">
			<div class="link">
				使用AskBot智能助手，轻松收集和整理微信、电脑或手机内的各类知识，AI自学并总结重点。通过对话你能随时找到所需内容，AIGC还能助力生成分享新知识。让知识倍增，快来试试吧！更多功能，请PC端体验。以上文案由AIGC自动生成。
				<div>{{inviteInfo.inviteWebUrl}}</div>
			</div>
			<div 
				class="copy-link"  
				v-clipboard:copy="inviteCodeCopy"
				v-clipboard:success="onCopy"
				v-clipboard:error="onError">
				复制邀请链接发送给好友
			</div>
			</div>
		</div>
		<div class="invite-progress">
			<div class="title">
			<span>已邀请</span>
			<span class="tips">
				{{inviteInfo.tipsTextPopup}}
				<!-- <span v-if="inviteInfo.inviteCount === 0">成功邀请1人获得30天VIP权限</span>
				<span v-else-if="inviteInfo.inviteCount === 1">再成功邀请2人获得30天VIP权限</span>
				<span v-else-if="inviteInfo.inviteCount > 1 && inviteInfo.inviteCount < 3">再成功邀请1人获得30天VIP权限</span>
				<span v-else-if="inviteInfo.inviteCount === 3">再成功邀请3人获得30天VIP权限</span>
				<span v-else-if="inviteInfo.inviteCount >= 3 && inviteInfo.inviteCount < 6">
					<template v-if="inviteInfo.inviteCount === 4">再成功邀请2人获得30天VIP权限</template>
					<template v-else>再成功邀请1人获得30天VIP权限</template>
				</span> -->
			</span>
			</div>
			<div class="progress">
			<div class="three-vip">
				<div class="step-line"></div>
				<div class="step-main">
				<span class="vip-num">30天VIP</span>
				<div class="step-icon-success" v-if="inviteInfo.inviteCount >= 1">
					<i class="iconfont guoran-a-16-17"></i>
				</div>
				<div class="step-icon-wait" v-else>
					<div class="centre-point"></div>
				</div>
				<span class="invite-num">邀请1人</span>
				</div>
			</div>
			<div class="seven-vip">
				<div class="step-line"></div>
				<div class="step-main">
				<span class="vip-num">再得30天VIP</span>
				<div class="step-icon-success" v-if="inviteInfo.inviteCount >= 3">
					<i class="iconfont guoran-a-16-17"></i>
				</div>
				<div class="step-icon-wait" v-else>
					<div class="centre-point"></div>
				</div>
				<span class="invite-num">再邀请2人</span>
				</div>
			</div>
			<div class="thirty-vip">
				<div class="step-line"></div>
				<div class="step-main step-3">
				<span class="vip-num">再得30天VIP</span>
				<div class="step-icon-success" v-if="inviteInfo.inviteCount >= 6">
					<i class="iconfont guoran-a-16-17"></i>
				</div>
				<div class="step-icon-wait" v-else>
					<div class="centre-point"></div>
				</div>
				<span class="invite-num">再邀请3人</span>
				</div>
			</div>
			</div>
		</div>
		</div>
		<!-- <div slot="dialog-footer" class="invite-footer">
			<div class="button cancel-btn" @click="isInviteFriends = false">取消</div>
			<div class="button confirm-btn" @click="isInviteFriends = false">保存</div>
		</div> -->
	</popup-com>
</template>

<script>
import PopupCom from "@/components/PopupCom";
// import { requestUrl } from "../../http/requestUrl";
export default {
  name: "InviteFriends",
	components: { PopupCom },
	data() {
		return {
			isInviteFriends:false,
			// inviteCodeCopy:"",
			// inviteInfo:{}
		}
	},
	props:{
		inviteInfo:{
            type:Object,
            default(){
                return {}
            }
        },
		inviteCodeCopy:{
			type:String,
			default:''
		}
	},
	// watch:{
	// 	isInviteFriends(n){
	// 		if(n){
	// 			this.getInfo();
	// 		}
	// 	}
	// },
	methods:{
		// // // 生成邀请信息
		// getInfo(){
		// 	this.$http.get(requestUrl.invite.getInfo + "?client=web").then((res) => {
		// 		console.log(res,'res');
		// 		if (res.data.code === "0") {
		// 			this.inviteInfo = res.data.data;
		// 			// this.inviteInfo.inviteCount = 6
		// 			this.inviteCodeCopy = `我正在用AskBot智能助手，AI帮你快速写作，整理凌乱的知识，快跟我一起用吧！以上文案由AIGC自动生成。${res.data.data.inviteWebUrl}`
		// 		}
		// 	});
		// },
		// Copy成功
		onCopy() {
			this.messageFn('success', '内容已复制到剪切板！');
		},
		// Copy失败
		onError() {
			this.messageFn('error', '抱歉，复制失败！');
		},
	}
};
</script>

<style scoped lang="less">
.invite-friend{
	.popup-con{
		text-align: left;
		.invite-link{
			.title{
			color: #616161;
			margin-bottom: 10px;
			}
			.link-container{
			border: 1px solid #E0E6F7;
			border-radius: 5px;
			.link{
				padding: 14px;
				max-height: 150px;
				overflow-x: hidden;
				overflow-y: auto;
				color: #000000;
				line-height: 24px
			}
			.copy-link{
				background: #F0F4FF;
				border-radius: 3px 0px 0px 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 0;
				color: #366aff;
				cursor: pointer;
			}
			}
		}
		.invite-progress{
			margin-top: 20px;
			.title{
			color: #616161;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.tips{
				color: #A9B3C6;
			}
			}
			.progress{
			margin-top: 10px;
			display: flex;
			background: #FBFCFD;
			border-radius: 5px;
			padding: 12px;
			.three-vip,.seven-vip,.thirty-vip{
				position: relative;
				display: flex;
				align-items: center;

			}
			.thirty-vip{
				width: calc(100% - 170px);
				position: relative;
			}
			.step-line{
				position: absolute;
				height: 5px;
				width: 100%;
				background-color: #E5E8F1;

			}
			.step-main{
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-left: 20px;
				z-index: 1;
				font-size: 13px;
				.vip-num{
					color: #616161;
					white-space: nowrap;
				}
				.step-icon-success{
					margin: 8px 0;
					flex: none;
					width: 20px;
					height: 20px;
					background: #09DDD5;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;

				i{
					color: #FFFFFF;
				}
				}
				.step-icon-wait{
				margin: 8px 0;
				flex: none;
				width: 20px;
				height: 20px;
				background-color: rgba(54, 106, 255,0.1);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				.centre-point{
					flex: none;
					width: 7px;
					height: 7px;
					background: #366AFF;
					border-radius: 50%;
				}
				}
				.invite-num{
				color: #000000;
				}
			}
			.step-3{
				position: absolute;
				right: -10px;
				align-items: end;
			}
			}
		}
	}
	.invite-footer{
		display: flex;
		align-items: center;
		.button{
			width: 90px;
			height: 30px;
			border-radius: 19px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 2px solid #FFFFFF;
			font-size: 13px;
			cursor:pointer;
		}
		.cancel-btn{
			background: #FFFFFF;
			border: 2px solid #E0E6F7;
			color: #000000;
			margin-right: 10px;
		}
		.confirm-btn{
			background: #366AFF;
			border: 2px solid #366AFF;
			color: #FFFFFF;
		}
	}
}

</style>